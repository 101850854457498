import { Component, OnInit, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfHandlerComponent } from 'src/app/InrPayCommon/pdf/pdf-handler/pdf-handler.component';
import { BBPSService } from 'src/app/services/BBPS/bbps.service';
import { UserService } from 'src/app/services/user/user.service';
import swal from 'sweetalert2'

@Component({
  selector: 'app-bill-confirm',
  templateUrl: './bill-confirm.component.html',
  styleUrls: ['./bill-confirm.component.scss']
})
export class BillConfirmComponent implements OnInit {

  @ViewChild('billCofirmModal') private billModal: TemplateRef<any>
  @ViewChild('billSuccessModal') private billSuccessModal: TemplateRef<any>
  @ViewChild(PdfHandlerComponent) private pdfHandler: PdfHandlerComponent;

  @Output()
  emitSuccess: EventEmitter<any> = new EventEmitter<any>()

  constructor(
    private modalService: NgbModal,
    private bbpsService: BBPSService,
    private router: Router,
    private userService: UserService
    // private pdfHandler:PdfHandlerComponent
  ) { }

  loading = false;
  ProceedBtn = 'PROCEED';

  orderDetails;
  paymentDetails;
  total;
  subtotal;
  image;

  billFields;

  ngOnInit(): void {

  }

  openModal(data) {
    this.orderDetails = data.orderDetails;
    this.paymentDetails = data.paymentDetails;
    this.total = data.total;
    this.subtotal = data.subTotal;
    this.image = data.image;
    this.modalService.open(this.billModal, { size: 'lg', backdrop: 'static', centered: true }).result.then(data => { }).catch(err => { })
  }

  openBillModal(data, billFields) {

    this.billFields = billFields;

    this.orderDetails = data[0].orderDetails;
    this.paymentDetails = data[0].paymentDetails;
    this.total = data[0].total;
    this.subtotal = data[0].subTotal;
    this.image = data[0].image;

    this.modalService.open(this.billModal, { size: 'lg', backdrop: 'static', centered: true }).result.then(data => { }).catch(err => { })
  }

  billPostData;
  successTransactionId;
  successMessage;
  categoryCode;
  printData;
  openBillConfirmModal(data, billPostData) {
    this.printData = data
    this.billPostData = billPostData
    this.orderDetails = data.orderDetails;
    this.paymentDetails = data.paymentDetails;
    this.total = data.total;
    this.subtotal = data.subTotal;
    this.image = data.image;
    this.categoryCode = billPostData.category_code

    this.modalService.open(this.billModal, { size: 'lg', backdrop: 'static', centered: true }).result.then(data => { }).catch(err => { })
  }

  payBillResponse;
  transactionId;
  successResponse;
  consumerNumber;
  consumerName;
  errorResponse;
  proceedBill() {
    this.ProceedBtn = 'PROCESSING...';
    this.loading = true;
    this.consumerNumber = this.billPostData.params;
    this.consumerName = this.billPostData.customerName

    //Api call here.....

    this.bbpsService.payBill(this.billPostData).subscribe(
      data => { 
        this.payBillResponse = data;
        this.loading = false;
        this.ProceedBtn = 'PROCEED';
        this.modalService.dismissAll();

        if (this.payBillResponse.message == 'BBPS FAILED') {
          var parsedJson = JSON.parse(this.payBillResponse.data[0])
          swal.fire({
            icon: 'error',
            title: parsedJson.statusMessage,
          })
        }

        if (this.payBillResponse.message == 'BBPS Succussfull') {
          this.successResponse = JSON.parse(this.payBillResponse.data[0])
          this.transactionId = this.successResponse.data.bbpsTxnId
          this.successMessage = this.successResponse.statusCode
          this.emitSuccess.emit('success');
          this.userService.setUserDetails()
          this.openBillSucccessModal();
        }


        if (this.payBillResponse.message == 'Insufficient Balance') {
          swal.fire({
            icon: 'error',
            title: 'Insufficient Balance',
          })
        }
      }, err => {
        this.loading = false;
        this.ProceedBtn = 'PROCEED';
        this.modalService.dismissAll();
        this.errorResponse = err.error
        swal.fire(
          { title: this.errorResponse.message, icon: 'error' }
        )
      }
    )
  }

  openBillSucccessModal() {
    this.modalService.open(this.billSuccessModal, {
      size: 'md',
      centered: true
    }).result.then(data => { }).catch(err => { })
  }

  recieptArray;

  today = new Date();
  todayDate = this.today.toDateString()
  

  downLoadReciept() {
    this.recieptArray = {
      orderNumber: "8555669955856",
      addressLine1: this.printData.shopName,
      addressLine2: this.printData.shopAddress,
      phone: this.printData.shopPhone,
      toBill:this.consumerNumber,
      toCustomer:this.consumerName,

      service: this.paymentDetails,
      amount: this.total,
      gst: "360",
      total: this.total,
      status:"Success",
      txn_no: this.transactionId,
      date:this.todayDate
    }
    this.pdfHandler.DownloadBbpsReciept('', this.recieptArray);
  }

  printReciept() {
    this.recieptArray = {
      orderNumber: "8555669955856",
      addressLine1: this.printData.shopName,
      addressLine2: this.printData.shopAddress,
      phone: this.printData.shopPhone,
      toBill:this.consumerNumber,
      toCustomer:this.consumerName,

      service: this.paymentDetails,
      amount: this.total,
      gst: "360",
      total: this.total,
      status:"Success",

      txn_no: this.transactionId,
      date:this.todayDate
    }
    this.pdfHandler.printBbpsReciept('', this.recieptArray)
  }

}
