// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBQSpMWp59ZfCBWBwtEHW-iSh1w1w5yuZM",
    authDomain: "wradmin-test.firebaseapp.com",
    projectId: "wradmin-test",
    storageBucket: "wradmin-test.appspot.com",
    messagingSenderId: "771576011313",
    appId: "1:771576011313:web:bec2f67ff8c5152b369310",
    measurementId: "G-6YHJN452P8"
  }
};


export const base = "url == "
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
