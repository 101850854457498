import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SendMoneyService } from './../../../../services/money-transfer/send-money.service';
import { WizardComponent as BaseWizardComponent } from 'angular-archwizard';
import { MustMatch } from '../../../../core/_helpers/must-match.validator'
import swal from 'sweetalert2'
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  @ViewChild('wizard') wizard: BaseWizardComponent;

  confirmPwdForm: FormGroup
  constructor(
    private newService: SendMoneyService,
    private formBuilder:FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService:AuthService
  ) { }

  OTPSent = false;
  phoneNumber;
  // submitted = false;
  pwdequal = true;
  resetKey;

  ngOnInit(): void {

    this.resetKey = this.activatedRoute.snapshot.params.key;
    this.confirmPwdForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirm_pwd: ['', Validators.required, ]
    })
  }

  /*************************************************
   * Reset password
   */
   passwordResetSubmitting = false;
   resetResponse;
   resetPasswordSubmit(){

    if(this.confirmPwdForm.value.password == this.confirmPwdForm.value.confirm_pwd){
      this.passwordResetSubmitting = true;
      const value = {
        password:this.confirmPwdForm.value.password,
        token:this.resetKey
      }
 
      if(value){
        this.authService.resetPassword(value).subscribe(
          data=>{
            this.passwordResetSubmitting = false
            this.resetResponse = data;
            if(this.resetResponse.message == "Password reseted"){
              swal.fire(
                { toast: true, position: 'top-end', showConfirmButton: false, timer: 1130, title: 'Password reseted Succesfully', icon: 'success' }
              )

              this.router.navigate(['/auth/login'])
            }  
            if(this.resetResponse.message == 'Invalid token'){
              swal.fire(
                { toast: true, position: 'top-end', showConfirmButton: false, timer: 1130, title: 'Invalid token', icon: 'error' }
              )
            }          
          }
        )
      }
      
    }else{
      swal.fire(
        { toast: true, position: 'top-end', showConfirmButton: false, timer: 1130, title: 'Password and confirm password are not same', icon: 'error' }
      )
    }

   }

}