import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newtab-error',
  templateUrl: './newtab-error.component.html',
  styleUrls: ['./newtab-error.component.scss']
})
export class NewtabErrorComponent implements OnInit {

  constructor() { }
  desc;
  title;
  type;

  ngOnInit(): void {
    this.type = "Oops"
    this.title = "Multiple Tabs restricted"
    this.desc = 'Oopps!! There wan an error. Please try agin later.'
  }

}
