import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { EncryptService } from 'src/app/services/encrypt/encrypt.service';
import * as Feather from 'feather-icons';

import swal from 'sweetalert2'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit ,AfterViewInit{

  @ViewChild('blockMessage') private blockMessage:ElementRef


  returnUrl: any;
  loginForm:FormGroup;
  loading = false;
  loginBtnText = "LOGIN";
  fieldTextType = false;
  passwordToggleIcon = "eye"

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private authService:AuthService,
    private formBuilder:FormBuilder,
    private commonService:CommonService,
    private encryptService:EncryptService,
    private modalService:NgbModal
    ) { }

    ngAfterViewInit(){
      Feather.replace();
    }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username:['',Validators.required],
      password:['',Validators.required]
    });
    this.authService.doLogout();
  }

  get f(){
    return this.loginForm.controls;
  }


  userdata;

  passwordToggle(){
    this.fieldTextType = !this.fieldTextType;
    if(this.fieldTextType == true){
      this.passwordToggleIcon = 'eye-off';
    }else{
      this.passwordToggleIcon = 'eye'
    }
    
  }


  //Login Form Submit
  tryLogin(){
    //Stop here when login form invalid...
    if(this.loginForm.invalid){
      return
    }
    
    this.loginBtnText = "";
    this.loading = true;

    const val = {
      username : this.f.username.value,
      password : this.f.password.value
    }

    this.authService.doLogin(val).subscribe(
      data=>{
        this.userdata = data;

        if(this.userdata.code == 201){
          this.modalService.open(this.blockMessage,{centered:true,size:'md'})
          this.loginBtnText = "LOGIN"
          this.loading = false
        // var error = err;
        }else{
          this.setUserInStorage(this.userdata.data.token,this.userdata.data.id,this.userdata.data.type,this.userdata.data.credit_user_status,this.userdata.data.permission);
        }
        // this.userdata.data.credit_user_status
      },
      err=>{
        this.loginBtnText = "LOGIN"
        this.loading = false
        var error = err;
        if(error.error.code == 400){
          swal.fire(
            { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: 'Login Failed. Please check username and password ...', icon: 'error' }
          )
        }else{
          swal.fire(
            { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: 'Login Failed. Please check your Internet', icon: 'error' }
          )
        }
      }
    )
  }


  //Set login data into localStorage
  setUserInStorage(data,id,user_type,status,permissions){
    localStorage.setItem('user_type',user_type)
    localStorage.setItem("user_data",data);
    localStorage.setItem('user_id',id);
    this.commonService.setHeaders();
    this.encryptService.setEncriptedPermissions(permissions)
    this.encryptService.setEncriptedStatus(status);
    this.encryptService.setEncryptedUserType(user_type);
    sessionStorage.setItem('newSession',data)
    setTimeout(() => {
      
      this.router.navigate(['/home/quick-links']);
      this.loading = false;
      this.loginBtnText = "LOGIN"
    }, 1000);
  }

  
  
}
