import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/Notification/notification.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EncryptService } from 'src/app/services/encrypt/encrypt.service';
import { userPermissions } from '../../../core/guard/user-permissions';
import { MENU } from '../sidebar/menu';
import { ChangePasswordUserComponent } from '../../inrPay/auth/change-password-user/change-password-user.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @ViewChild(ChangePasswordUserComponent) changePasswordUserComponent: ChangePasswordUserComponent

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private notificationService: NotificationService,
    private firebaseService: FirebaseService,
    private userService: UserService,
    private authService: AuthService,
    private encryptService: EncryptService
  ) { }

  count;
  notifications;
  walletAmount = 0;
  walletBalance;
  userName = "";
  userEmail = "";
  virtualBalanceUser = "NO";
  creditUser = "NO";
  retailerId;
  masterParentId;

  virtualBalance;
  commissionflag;
  commissionwallet;

  ngOnInit(): void {
    // this.notificationService.setActiveNav.subscribe(
    //   data => {
    //     this.notifications = data
    //     this.count = this.notifications.length
    //   }
    // )

    this.getWalletUpdationDetails()

    this.getWalletDetails();
    this.getUserDetails();
    this.setPermissions();
    this.getPermissions()
    // this.userService.userDetails.next('')
    // this.userService.setUserDetails();


    this.userService.userDetails.subscribe(
      data => {
        this.userDetailsResponseTemp = data;
        this.userDetailsResponse = this.userDetailsResponseTemp.data[0];
        this.walletAmount = this.userDetailsResponse.userwallet;
        this.userEmail = this.userDetailsResponse.usernemail;
        this.userName = this.userDetailsResponse.username;
        this.retailerId = this.userDetailsResponse.retailerid;
        this.masterParentId = this.userDetailsResponse.master_parent;
        this.virtualBalance = this.userDetailsResponse.us_virtual_balance;
        this.virtualBalanceUser = this.userDetailsResponse.virtual_user;
        this.commissionflag = this.userDetailsResponse.us_commission_flag;
        this.commissionwallet = this.userDetailsResponse.usercommision;
      }
    )
  }


  /******************************************
   * Get virtual disaplay ... 
   */
  get virtualDisplay() {
    if (this.virtualBalanceUser == 'YES') {

      if (this.retailerId == this.masterParentId) {
        return true;
      } else {
        return false;
      }
    } else {
      return false
    }
  }


  get commissionDisplay() {
    if (this.commissionflag == '1') {
      return true;
    } else {
      return false
    }
  }

  /********************************************
   * GET USER DETAILS ....
   */
  userDetailsResponseTemp;
  userDetailsResponse;
  userId;
  getUserDetails() {
    this.userDetailsResponseTemp = "";
    this.userDetailsResponse = "";
    this.walletAmount = 0;
    this.userName = "";
    this.userEmail = ""
    this.userService.getUserDetails().subscribe(
      data => {
        this.userDetailsResponseTemp = data;
        this.userDetailsResponse = this.userDetailsResponseTemp.data[0];
        this.walletAmount = this.userDetailsResponse.userwallet;
        this.userEmail = this.userDetailsResponse.usernemail;
        this.userName = this.userDetailsResponse.username;
        this.userId = this.userDetailsResponse.retailerid
        this.encryptService.setEncriptedPermissions(this.userDetailsResponse.permission)
        this.encryptService.setEncryptedUserType(this.userDetailsResponse.usertype)
        this.encryptService.setEncriptedStatus(this.userDetailsResponse.credit_user_status)
        this.userService.userDetails.next(data);
      }
    )
  }

  /**************************************************************
   * Set user permissions ... ... .. .
   */
  permissionString = "";
  setPermissions() {
    var userPermissionsData = userPermissions.permissions[0].data.permissions;
    for (var i = 0; i < userPermissionsData.length; i++) {
      if (i == 0) {
        this.permissionString = userPermissionsData[i]
      } else {
        this.permissionString = this.permissionString + "," + userPermissionsData[i]
      }
    }

    // this.encryptService.setEncriptedPermissions(this.permissionString);
  }

  /*************************************************************************
   * Wallet updation realtime...
   */
  walletBalanceUserId
  getWalletUpdationDetails() {
    this.firebaseService.getWalletUpdateDetails().subscribe(
      data => {
        this.walletBalanceUserId = data.map(e => {
          return {
            id: e.payload.doc.id,
            data: e.payload.doc.data()
          }
        })



        if (this.walletBalanceUserId.length != 0) {
          var userId = this.encryptService.decryptUserId(this.walletBalanceUserId[0].data.user_id)

          if (userId == this.userId) {
            this.userService.setUserDetails();
          }

        }
      }
    )
  }




  getWalletDetails() {
    // this.firebaseService.getWalletDetails().subscribe(
    //   data =>{
    //     this.walletBalance = data.map(e=>{
    //       return {
    //         id: e.payload.doc.id,
    //         data:e.payload.doc.data()
    //       }
    //     })

    //     if(this.walletBalance.length !=0){
    //     this.walletAmount = this.walletBalance[0].data.wallet_balance
    //     }
    //   }
    // )
  }

  userPermissions;



  /** 
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {
    //  e.preventDefault();
    //this.authService.doLogout()
    // localStorage.removeItem('user_data');
    // localStorage.removeItem('user_id');
    // localStorage.removeItem('user_type');

    this.router.navigate(['/auth/login'])
    setTimeout(() => {
      location.reload();
    }, 500);
    // if (!localStorage.getItem('user_data')) {
    //   this.router.navigate(['/auth/login']);
    // }
  }


  searchMenu(event) {
    var searchDiv = document.getElementById('searchDiv');
    this.subElements = this.subElementsBackup;
    if (event.target.value != '') {
      this.subElements = this.subElements.filter(result => {
        if (result && event.target.value) {
          if (result.label.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1) {
            return true;
          }
          return false;
        }
      })
      searchDiv.classList.remove('d-none');
      searchDiv.classList.add('d-block')
    } else {
      this.subElements = this.subElementsBackup
      searchDiv.classList.remove('d-block');
      searchDiv.classList.add('d-none')
    }

  }

  menuItems = [];
  subElements = [];
  subElementsBackup = []

  getPermissions() {

    this.subElements = [];
    var permissionString = this.encryptService.getDecryptedPermissions();
    var perArray = permissionString.split(',');
    var replacedArray = [];

    perArray.forEach(element => {
      element = element.replace(/['"]+/g, '');
      replacedArray.push(element)
    });



    //this.logginedUser = localStorage.getItem('user_type');

    this.menuItems = MENU;

    // for (var m = 0; m < this.userPermissions.length; m++) {
    //   if (this.userPermissions[m].data.user_type == this.logginedUser) {
    //     this.selectedPermissions = this.userPermissions[m].data.permissions;
    //   }
    // }

    for (var i = 0; i < this.menuItems.length; i++) {
      this.menuItems[i].status = "";
      for (var j = 0; j < replacedArray.length; j++) {
        if (this.menuItems[i].permissionCode == replacedArray[j]) {
          this.menuItems[i].status = 1;

          if (this.menuItems[i].subItems) {
            this.menuItems[i].subItems.forEach(element => {
              element.status = ""
              replacedArray.forEach(element_per => {
                if (element.permissionCode == element_per) {
                  element.status = 1
                }
              });
            });
          }

          //this.menubinded = true;

          // new MetisMenu(side);


        }
      }
    }


    this.menuItems.forEach(element => {
      if (element.subItems) {
        element.subItems.forEach(elementSub => {
          if (elementSub.status != '') {
            this.subElements.push(elementSub);
            this.subElementsBackup.push(elementSub)
          }
        })
      }
    })

  }


  /******************************************
   * Change Password... 
   */
  changePassword() {
    this.changePasswordUserComponent.openOTPModal()
  }




  // SearchBeneficiaries(event) {
  //   const searchkey = event.currentTarget.value;
  //   this.beneficiariesDetails = this.beneficiariesDetailsBackup;
  //   if (searchkey != '') {
  //     this.beneficiariesDetails = this.beneficiariesDetails.filter(result => {
  //       if (result && searchkey) {
  //         if (result.bd_accno_holdername.toLowerCase().indexOf(searchkey.toLowerCase()) > -1 ||
  //           result.bd_accno.toLowerCase().indexOf(searchkey.toLowerCase()) > -1) {
  //           return true;
  //         }
  //         return false;
  //       }
  //     })
  //   } else {
  //     this.beneficiariesDetails = this.beneficiariesDetailsBackup;
  //   }
  // }


  @HostListener('document:click', ['$event'])
  clickout(event) {
    var searchDiv = document.getElementById('searchDiv');
    searchDiv.classList.remove('d-block');
    searchDiv.classList.add('d-none')
  }

}
