import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SendMoneyService } from './../../../../services/money-transfer/send-money.service';
import { WizardComponent as BaseWizardComponent } from 'angular-archwizard';
import { MustMatch } from '../../../../core/_helpers/must-match.validator'
import swal from 'sweetalert2'
import { AuthService } from 'src/app/services/auth/auth.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  @ViewChild('wizard') wizard: BaseWizardComponent;
  @ViewChild('otpTag') otpTag: ElementRef;
  @ViewChild('phnSend') phnSend: ElementRef;

  sendOtpForm: FormGroup
  confirmPwdForm: FormGroup
  constructor(
    private newService: SendMoneyService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) { }

  OTPSent = false;
  phoneNumber;
  // submitted = false;
  pwdequal = true;

  ngOnInit(): void { 
    this.sendOtpForm = this.formBuilder.group({
      phone: ['', Validators.compose(
        [Validators.required,
        Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{10}$/)
        ])],
    })

    this.confirmPwdForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirm_pwd: ['', Validators.required,]
    })
  }

  loading = false;
  OTP = "123456";
  OTP_from_User;
  settings = {
    length: 6,
    numbersOnly: true,
    timer: 0
  }

  //Send otp----------------
  resetPassResponse;
  selectedOtpId;
  sendOtp() {
    this.settings = {
      length: 6,
      numbersOnly: true,
      timer: 100,
    }

    const value = {
      mobile: this.sendOtpForm.value.phone
    }

    this.authService.resetPasswordOtp(value).subscribe(
      data => {
        this.resetPassResponse = data;
        this.selectedOtpId = this.resetPassResponse.data.otp_id;
        
        if(this.resetPassResponse.message == 'User does not exist'){
          swal.fire(
            { toast: true, position: 'top-end', showConfirmButton: false, timer: 1130, title: this.resetPassResponse.message, icon: 'error' }
          )
        }
        else{
          this.OTPSent = true;
          swal.fire(
            { toast: true, position: 'top-end', showConfirmButton: false, timer: 1130, title: 'OTP Sent to your mobile number ...', icon: 'success' }
          )
        }
      }, err => {
        // this.OTPSent = true;
        swal.fire(
          { toast: true, position: 'top-end', showConfirmButton: false, timer: 1130, title: 'Something went wrong  Please try again ... ', icon: 'error' }
        )
      }
    )
  }

  //resend otp------------

  otpSubmitResponse;
  onInputChange(e) {
    if (e.length == this.settings.length) {
      this.OTP_from_User = e;

      const value = {
        otpId: this.selectedOtpId,
        otp: this.OTP_from_User,
      }
      this.authService.submitResetPasswordOtp(value).subscribe(
        data => {
          this.otpSubmitResponse = data;

          if(this.otpSubmitResponse.message == 'Invalid otp'){
            swal.fire(
              { toast: true, position: 'top-end', showConfirmButton: false, timer: 1130, title: this.otpSubmitResponse.message, icon: 'error' }
            )
          }else{
            swal.fire(
              { toast: true, position: 'top-end', showConfirmButton: false, timer: 1130, title: 'Mobile Number Verified', icon: 'success' }
            )
            this.wizard.navigation.goToNextStep();
          }
        }, err => {
          this.otpSubmitResponse = err;
          swal.fire(
            { toast: true, position: 'top-end', showConfirmButton: false, timer: 1130, title: 'Something went wrong ....', icon: 'error' }
          )
        }
      )
      
    } else if (e == -1) {      
    } else if (e == -2) {
      alert("Resend otp?")
      this.sendOtp()
    }
  }

  //Change Number---------------

  changeNumber() {
    this.OTPSent = false;

    this.sendOtpForm.controls['phone'].enable();
  }

  //Confirm Submit Butrton---------- 
  resetPasswordResponse;
  resetPasswordText = "RESET PASSWORD";
  forgotloading = false

  confirmPassword() {
    if (this.confirmPwdForm.value.password == this.confirmPwdForm.value.confirm_pwd) {
      if(this.confirmPwdForm.value.password.length >= 8){
        this.resetPasswordText = "PROCESSING ...";
        this.forgotloading = true;
        const value = {
          mobile: this.sendOtpForm.value.phone,
          password: this.confirmPwdForm.value.password
        }
  
        this.authService.resetPasswordSubmit(value).subscribe(
          data => {
            this.resetPasswordText = "RESET PASSWORD";
            this.forgotloading = false;
            this.resetPasswordResponse = data;
            swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 1130,
              title: "Successfully Updated",
              icon: "success"
            })
            this.router.navigate(['/auth/login'])
          },err=>{
            this.resetPasswordText = "RESET PASSWORD";
            this.forgotloading = false;
            swal.fire(
              { toast: true, position: 'top-end', showConfirmButton: false, timer: 1130, title: 'Something went wrong please try again ...', icon: 'error' }
            )
          }
        )
      }else{
        swal.fire(
          { toast: true, position: 'top-end', showConfirmButton: false, timer: 1130, title: 'The password must be at least 8 characters ...', icon: 'error' }
        )
        
      }
      
    }else{
      swal.fire(
        { toast: true, position: 'top-end', showConfirmButton: false, timer: 1130, title: 'Password and confirm password are not equal ...', icon: 'error' }
      )
    }
  }


  get f() {
    return this.sendOtpForm.controls;
  }

  get g() {
    return this.confirmPwdForm.controls;
  }
}
