import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillConfirmComponent } from './bill-confirm/bill-confirm.component';
import { BbpsImageComponent } from './bbps-image/bbps-image.component';
import { PdfModule } from 'src/app/InrPayCommon/pdf/pdf.module';
import { BlockedPageComponent } from './blocked-page/blocked-page.component';
import { DownloadIndicatorComponent } from './download-indicator/download-indicator.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
// import { WebsiteLoaderComponent } from './website-loader/website-loader.component';

@NgModule({
  declarations: [BillConfirmComponent, BbpsImageComponent, BlockedPageComponent, DownloadIndicatorComponent, DateRangePickerComponent],
  imports: [
    CommonModule,
    PdfModule,
    NgbModule
  ],
  exports:[BillConfirmComponent,BbpsImageComponent,BlockedPageComponent,DownloadIndicatorComponent,DateRangePickerComponent]
})
export class CommonComponentsModule { }
