export class userPermissions {
    public static permissions = [
        {
            data:{
                
                    user_type:"Admin",
                    permissions: [
                        "USER_TYPE",
                        "USER",
                        // "MONEY_TRANSFER",
                        "MONEY_TRANSFER",
                        
                        "HOME",
                        // "DASHBOARD",
                        "INRPAY",
                        "VENDORS",
                        "COMMISSION",
                        "DMT",
                        "BILLS",
                        "REPORTS",
                        "SETTINGS",
                        "MENU_SETTINGS",
                        "MENU_SETT",
                        "RECHARGE",
                        "AEPS",

                        //Childs ....
                        "TRANS_REPORT",
                        "STOCK_TRANS",
                        "CREATE_USERTYPE",
                        "USERSTYPE_LIST",
                        "USERS_LIST",
                        "CREATE_USER",
                        "VENDORS_LIST",
                        "COMM_PROFILE",
                        "SLABS",
                        "WALLET",
                        "SEND_M",
                        "WITHDRAW",
                        "BALANCE",
                        "MINI_STATEMENT",
                        "MOB_RECHARGE",
                        "DTH",
                        "BILL_ELECTRICITY",
                        "BILL_WATER",
                        "BILL_GAS",
                        "BILL_INSURANCE",
                        "BILL_",
                        "MENU_SETT",
                        "STOCK_REPORT",
                        "DMT_TRANS",
                        "USAGE_REP",
                        "WALLET_REP",
                        "KYC_REP",

                        "REQUESTS",
                        "CRED_REQUEST",

                        // "MOBILE_RECHARGE",
                        "MOBILE_RECHARGE",
                        "DTH_RECHARGE"
                        // "MOBILE_REP"
                    ]
            }
            
        },
        {
            data:{
                
                    user_type:"Retailer",
                    permissions: [
                        // "USERTYPE",
                        // "USER",
                        "MONEY_TRANSFER",
                        "HOME",
                        // "DASHBOARD",
                        "INRPAY",
                        "DMT",
                        "BILLS",
                        "REPORTS",
                        "RECHARGE",
                        "AEPS",

                        "STOCK_TRANS",
                        
                        "SEND_M",
                        "WITHDRAW",
                        "BALANCE",
                        "MINI_STATEMENT",
                        "MOB_RECHARGE",
                        "DTH",
                        "BILL_ELECTRICITY",
                        "BILL_WATER",
                        "BILL_GAS",
                        "BILL_INSURANCE",
                        "BILL_",
                        "MENU_SETT",
                        "DMT_TRANS",

                        "STOCK_REPORT",
                        "DMT_TRANS",
                        // "USAGE_REP",
                        // "TRANS_REPORT",

                        "MOBILE_REP",

                        "TRANS_USER_REP",

                        "MOBILE_RECHARGE",
                        "DTH_RECHARGE"
                    ]
            }
            
        },
        {
            data:{
                
                    user_type:"Distributer",
                    permissions: [
                        // "USERTYPE",
                        "USER",
                        "MONEY_TRANSFER",
                        "HOME",
                        // "DASHBOARD",
                        "INRPAY",
                        "DMT",
                        "BILLS",
                        "REPORTS",
                        "RECHARGE",
                        "AEPS",

                        "STOCK_TRANS",
                        "TRANS_REPORT",
                        "SEND_M",
                        "WITHDRAW",
                        "BALANCE",
                        "MINI_STATEMENT",
                        "MOB_RECHARGE",
                        "DTH",
                        "BILL_ELECTRICITY",
                        "BILL_WATER",
                        "BILL_GAS",
                        "BILL_INSURANCE",
                        "BILL_",
                        "MENU_SETT",

                        "USERS_LIST",
                        "CREATE_USER",
                        "DMT_TRANS",

                        "STOCK_REPORT",
                        // "DMT_TRANS",
                        "USAGE_REP",
                        // "TRANS_REPORT",

                        "MOBILE_REP",
                        "TRANS_USER_REP",

                        "MOBILE_RECHARGE",
                        "DTH_RECHARGE"
                        
                    ]
            }
            
        },
        {
            data:{
                
                    user_type:"Sales Staff With Wallet",
                    permissions: [
                        // "USERTYPE",
                        "USER",
                        "MONEY_TRANSFER",
                        "HOME",
                        // "DASHBOARD",
                        "INRPAY",
                        "DMT",
                        "BILLS",
                        "REPORTS",
                        "RECHARGE",
                        "AEPS",

                        "STOCK_TRANS",
                        "TRANS_REPORT",
                        "SEND_M",
                        "WITHDRAW",
                        "BALANCE",
                        "MINI_STATEMENT",
                        "MOB_RECHARGE",
                        "DTH",
                        "BILL_ELECTRICITY",
                        "BILL_WATER",
                        "BILL_GAS",
                        "BILL_INSURANCE",
                        "BILL_",
                        "MENU_SETT",

                        "USERS_LIST",
                        "CREATE_USER",

                        "DMT_TRANS",
                        // "TRANS_REPORT",

                        "STOCK_REPORT",
                        // "DMT_TRANS",
                        "USAGE_REP",
                        // "TRANS_REPORT",
                        "TRANS_USER_REP",

                        "MOBILE_RECHARGE",
                        "DTH_RECHARGE"
                    ]
            }
            
        },
        {
            data:{
                
                    user_type:"Sales Staff Without Wallet",
                    permissions: [
                        // "USERTYPE",
                        "USER",
                        "MONEY_TRANSFER",
                        "HOME",
                        // "DASHBOARD",
                        "INRPAY",
                        "DMT",
                        "BILLS",
                        "REPORTS",
                        "RECHARGE",
                        "AEPS",

                        "STOCK_TRANS",
                        "TRANS_REPORT",
                        "SEND_M",
                        "WITHDRAW",
                        "BALANCE",
                        "MINI_STATEMENT",
                        "MOB_RECHARGE",
                        "DTH",
                        "BILL_ELECTRICITY",
                        "BILL_WATER",
                        "BILL_GAS",
                        "BILL_INSURANCE",
                        "BILL_",
                        "MENU_SETT",

                        "USERS_LIST",
                        "CREATE_USER",

                        "DMT_TRANS",
                        "TRANS_USER_REP",

                        "MOBILE_RECHARGE",
                        "DTH_RECHARGE"
                    ]
            }
            
        },
        {
            data:{
                
                    user_type:"CreditUser",
                    disabled:false,
                    permissions: [
                        // "USERTYPE",
                        // "USER",
                        "MONEY_TRANSFER",
                        "HOME",
                        // "DASHBOARD",
                        "INRPAY",
                        "DMT",
                        "BILLS",
                        "REPORTS",
                        "RECHARGE",
                        "AEPS",

                        "STOCK_TRANS",
                        
                        "SEND_M",
                        "WITHDRAW",
                        "BALANCE",
                        "MINI_STATEMENT",
                        "MOB_RECHARGE",
                        "DTH",
                        "BILL_ELECTRICITY",
                        "BILL_WATER",
                        "BILL_GAS",
                        "BILL_INSURANCE",
                        "BILL_",
                        "MENU_SETT",
                        "DMT_TRANS",

                        "STOCK_REPORT",
                        "DMT_TRANS",
                        // "USAGE_REP",
                        // "TRANS_REPORT",

                        "MOBILE_REP",

                        "TRANS_USER_REP",

                        "MOBILE_RECHARGE",
                        "DTH_RECHARGE"
                    ]
            }
            
        },



        {
            data:{
                
                    user_type:"Reseller",
                    permissions: [
                        // "USERTYPE",
                        "USER",
                        "MONEY_TRANSFER",
                        "HOME",
                        // "DASHBOARD",
                        "INRPAY",
                        "DMT",
                        "BILLS",
                        "REPORTS",
                        "RECHARGE",
                        "AEPS",

                        "STOCK_TRANS",
                        "TRANS_REPORT",
                        "SEND_M",
                        "WITHDRAW",
                        "BALANCE",
                        "MINI_STATEMENT",
                        "MOB_RECHARGE",
                        "DTH",
                        "BILL_ELECTRICITY",
                        "BILL_WATER",
                        "BILL_GAS",
                        "BILL_INSURANCE",
                        "BILL_",
                        "MENU_SETT",

                        "USERS_LIST",
                        "CREATE_USER",
                        "DMT_TRANS",

                        "STOCK_REPORT",
                        // "DMT_TRANS",
                        "USAGE_REP",
                        // "TRANS_REPORT",

                        "MOBILE_REP",
                        "TRANS_USER_REP",

                        "MOBILE_RECHARGE",
                        "DTH_RECHARGE"
                        
                    ]
            }
            
        },

        {
            data:{
                
                    user_type:"Distributer New",
                    permissions: [
                        // "USERTYPE",
                        "USER",
                        "MONEY_TRANSFER",
                        "HOME",
                        // "DASHBOARD",
                        "INRPAY",
                        "DMT",
                        "BILLS",
                        "REPORTS",
                        "RECHARGE",
                        "AEPS",

                        "STOCK_TRANS",
                        "TRANS_REPORT",
                        "SEND_M",
                        "WITHDRAW",
                        "BALANCE",
                        "MINI_STATEMENT",
                        "MOB_RECHARGE",
                        "DTH",
                        "BILL_ELECTRICITY",
                        "BILL_WATER",
                        "BILL_GAS",
                        "BILL_INSURANCE",
                        "BILL_",
                        "MENU_SETT",

                        "USERS_LIST",
                        "CREATE_USER",
                        "DMT_TRANS",

                        "STOCK_REPORT",
                        // "DMT_TRANS",
                        "USAGE_REP",
                        // "TRANS_REPORT",

                        "MOBILE_REP",
                        "TRANS_USER_REP",

                        "MOBILE_RECHARGE",
                        "DTH_RECHARGE"
                        
                    ]
            }
            
        },


        
    ]
}