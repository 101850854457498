import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfHandlerComponent } from './pdf-handler/pdf-handler.component';



@NgModule({
  declarations: [PdfHandlerComponent],
  imports: [
    CommonModule
  ],
  exports:[PdfHandlerComponent]
})
export class PdfModule { }
