import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { EncryptService } from 'src/app/services/encrypt/encrypt.service';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { userPermissions } from './user-permissions'

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private permissionService: PermissionsService, private encryptService: EncryptService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.permissionService.getPermissionDetails();
    var usertype = this.encryptService.getDecryptedUserType();
    var isAdmin = '"Admin"';

    console.log(usertype, " === ", isAdmin)

    if (usertype != isAdmin) {
      if (sessionStorage.getItem('newSession')) {
        if (localStorage.getItem('user_data')) {
          // logged in so return true
          return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/auth/login']);
        // , { queryParams: { returnUrl: state.url } }
        return false;
      }
      else {
        this.router.navigate(['err']);
        return false;
      }
    } else {
      if (localStorage.getItem('user_data')) {
        // logged in so return true
        return true;
      }

      // not logged in so redirect to login page with the return url
      this.router.navigate(['/auth/login']);
      // , { queryParams: { returnUrl: state.url } }
      return false;
    }


  }
}

@Injectable()
export class AuthGuardModule implements CanActivate {

  constructor(
    private router: Router,
    private permissionService: PermissionsService
  ) {

  }

  permissions = userPermissions.permissions;
  loginedUser = localStorage.getItem('user_type');

  a = 1;
  success = 0;
  completed = 0;
  permissionsData;
  count = 0

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    this.count = this.count + 1;
    // if(this.count == 1 ){
    //   setTimeout(() => {
    //     this.router.navigate([state.url])
    //   }, 1000);
    // }else{
    //   return this.checkPermissions(route, state)
    // }
    return this.checkPermissions(route, state)
    // }

    // }
    //var success = 0;
    // if(route.data.page_name != undefined){
    //   this.permissions.forEach(element => {
    //     if (element.usertype == this.loginedUser) {
    //       element.permissions.forEach(element_sub => {
    //         if (element_sub == route.data.page_name) {
    //           this.success = 1
    //           return true
    //         } 
    //       });
    //       if(this.success == 1){
    //         //this.router.navigate(['/user-type/company/create'])
    //         return true

    //       }else{
    //         this.router.navigate(['/dashboard'], { queryParams: { returnUrl: state.url } });
    //         return false
    //       }
    //     }
    //   });
    // }else{
    //   return true
    // }
    //  return true
    // return true

    // if(this.a==1){
    //   return true
    // }
    // else{
    //   this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    //   return false;
    // }
  }


  checkPermissions(route, state) {
    return this.permissionService.hasPermission(route, state)
    // return true
  }

  // returnPermission(route, state) {

  //   if (route.data.page_name != undefined) {
  //     for (var i = 0; i < this.permissions.length; i++) {
  //       if (this.permissions[i].usertype == this.loginedUser) {
  //         for (var j = 0; j < this.permissions[i].permissions.length; j++) {
  //           if (this.permissions[i].permissions[j] == route.data.page_name) {
  //             //return true;
  //             this.success = 1;
  //             this.completed = 1
  //           }
  //         }
  //       }
  //     }

  //     // if(this.completed == 1){
  //     if (this.success == 1) {
  //       return true
  //     } else {
  //       this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
  //       return false
  //     }
  //   } else {
  //     return true
  //   }
  // }
}


@Injectable()
export class AuthGuardChild implements CanActivate {
  constructor(private router: Router, private permissionService: PermissionsService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.permissionService.hasChildPermission(route, state)
    // this.permissionService.getPermissionDetails()
    // if (localStorage.getItem('user_data')) {
    //   // logged in so return true

    //   return true;

    // }

    // // not logged in so redirect to login page with the return url
    // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    // return false;
  }
}

