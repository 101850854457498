import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2, Inject, OnChanges, AfterContentInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import MetisMenu from 'metismenujs/dist/metismenujs';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { Router, NavigationEnd } from '@angular/router';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { userPermissions } from '../../../core/guard/user-permissions'
import { EncryptService } from 'src/app/services/encrypt/encrypt.service';
import { DownloadService } from 'src/app/services/download/download.service';
import { DownloadIndicatorComponent } from '../../inrPay/common-components/download-indicator/download-indicator.component';

declare const $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('sidebarToggler') sidebarToggler: ElementRef;
  @ViewChild(DownloadIndicatorComponent) downloadIndicator: DownloadIndicatorComponent

  menuItems = [];
  @ViewChild('sidebarMenu') sidebarMenu: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    router: Router,
    private permissionService: PermissionsService,
    private encryptService: EncryptService,
    private downloadService: DownloadService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {

        /**
         * Activating the current active item dropdown
         */
        this._activateMenuDropdown();

        /**
         * closing the sidebar
         */
        if (window.matchMedia('(max-width: 991px)').matches) {
          this.document.body.classList.remove('sidebar-open');
        }

      }
    });
  }

  ngOnDestroy() {
    //alert("hiii")
  }

  ngOnInit(): void {
    //this.downloadService.setDownloadDetails()
    this.downloadService.downloadDetails.subscribe(
      data => {
        this.downloadIndicator.setDownloadProgress(data)
      }
    )

    if (localStorage.getItem('theme')) {
      var theme = localStorage.getItem('theme');
      this.onBackgroundChange(theme);
      this.setActive(theme)
    } else {
      this.setActive('sidebar-light')
    }



    this.getPermissions();

    /**
     * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
     */
    const desktopMedium = window.matchMedia('(min-width:992px) and (max-width: 1199px)');
    desktopMedium.addListener(this.iconSidebar);
    this.iconSidebar(desktopMedium);
  }

  ngAfterViewInit() {
    // activate menu item;

    setTimeout(() => {
      new MetisMenu(this.sidebarMenu.nativeElement);
      this._activateMenuDropdown();
    }, 0);

  }

  // ngAfterContentInit(){
  //   new MetisMenu(this.sidebarMenu.nativeElement);
  //     this._activateMenuDropdown();
  // }

  // ngOnChanges(){
  //   new MetisMenu(this.sidebarMenu.nativeElement);
  //              this._activateMenuDropdown();
  // }


  userPermissions = userPermissions.permissions;
  logginedUser;
  selectedPermissions;
  menubinded = false;

  getPermissions() {

    var permissionString = this.encryptService.getDecryptedPermissions();
    var perArray = permissionString.split(',');
    var replacedArray = [];

    perArray.forEach(element => {
      element = element.replace(/['"]+/g, '');
      replacedArray.push(element)
    });



    this.logginedUser = localStorage.getItem('user_type');

    this.menuItems = MENU;

    for (var m = 0; m < this.userPermissions.length; m++) {
      if (this.userPermissions[m].data.user_type == this.logginedUser) {
        this.selectedPermissions = this.userPermissions[m].data.permissions;
      }
    }

    for (var i = 0; i < this.menuItems.length; i++) {
      this.menuItems[i].status = "";
      for (var j = 0; j < replacedArray.length; j++) {
        if (this.menuItems[i].permissionCode == replacedArray[j]) {
          this.menuItems[i].status = 1;

          if (this.menuItems[i].subItems) {
            this.menuItems[i].subItems.forEach(element => {
              element.status = ""
              replacedArray.forEach(element_per => {
                if (element.permissionCode == element_per) {
                  element.status = 1
                }
              });
            });
          }

          this.menubinded = true;

          // new MetisMenu(side);


        }
      }
    }

  }



  // getPermissions() {
  //  // var side = document.getElementById('sideBarM')
  //   //this.menuItems = MENU;
  //   this.permissionService.getPermissionDetailsMenu().subscribe(
  //     data => {
  //       this.userPermissions = data.map(e => {
  //         return {
  //           id: e.payload.doc.id,
  //           data: e.payload.doc.data()
  //         }
  //       })

  //       this.menuItems = MENU;

  //       for (var m = 0; m < this.userPermissions.length; m++) {
  //         if (this.userPermissions[m].data.user_type == this.logginedUser) {
  //           this.selectedPermissions = this.userPermissions[m].data.permissions;
  //         }
  //       }

  //       for (var i = 0; i < this.menuItems.length; i++) {
  //         this.menuItems[i].status = "";
  //         for (var j = 0; j < this.selectedPermissions.length; j++) {
  //           if (this.menuItems[i].permissionCode == this.selectedPermissions[j]) {
  //             this.menuItems[i].status = 1;
  //             this.menubinded = true;

  //             // new MetisMenu(side);


  //           }
  //         }
  //       }
  //       //$('#sideBarM').metisMenu();
  //       // this.ngAfterViewInit();
  //     }

  //   )
  // }

  /**
   * Toggle sidebar on hamburger button click
   */
  toggleSidebar(e) {
    this.sidebarToggler.nativeElement.classList.toggle('active');
    this.sidebarToggler.nativeElement.classList.toggle('not-active');
    if (window.matchMedia('(min-width: 992px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-folded');
    } else if (window.matchMedia('(max-width: 991px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-open');
    }
  }


  /**
   * Toggle settings-sidebar 
   */
  toggleSettingsSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('settings-open');
  }


  /**
   * Open sidebar when hover (in folded folded state)
   */
  operSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.add("open-sidebar-folded");
    }
  }


  /**
   * Fold sidebar after mouse leave (in folded state)
   */
  closeSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.remove("open-sidebar-folded");
    }
  }

  /**
   * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
   */
  iconSidebar(e) {
    if (e.matches) {
      this.document.body.classList.add('sidebar-folded');
    } else {
      this.document.body.classList.remove('sidebar-folded');
    }
  }


  /**
   * Switching sidebar light/dark
   */
  onSidebarThemeChange(event) {
    this.document.body.classList.remove('sidebar-light', 'sidebar-dark');
    this.document.body.classList.add(event.target.value);
    this.document.body.classList.remove('settings-open');
    this.setTheme(event.target.value)
  }

  onBackgroundChange(event) {
    this.document.body.classList.remove('sidebar-light', 'sidebar-dark');
    this.document.body.classList.add(event);
    this.document.body.classList.remove('settings-open');

    this.setActive(event);
    this.setTheme(event)
  }

  /***
   * Set Active theme link
   * ** */

  setActive(event) {
    var dark = document.getElementById('sidebar-dark');
    var light = document.getElementById('sidebar-light');
    var id = document.getElementById(event);

    dark.classList.remove('active');
    light.classList.remove('active');
    id.classList.add('active')
  }

  /***
   * Set theme mode into localStorge
   * ****/

  setTheme(event) {
    localStorage.setItem('theme', event)
  }


  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }


  /**
   * Reset the menus then hilight current active menu item
   */
  _activateMenuDropdown() {
    this.resetMenuItems();
    this.activateMenuItems();
  }


  /**
   * Resets the menus
   */
  resetMenuItems() {

    const links = document.getElementsByClassName('nav-link-ref');

    for (let i = 0; i < links.length; i++) {
      const menuItemEl = links[i];
      menuItemEl.classList.remove('mm-active');

      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.remove('mm-active');
        const parent2El = parentEl.parentElement;

        if (parent2El) {
          parent2El.classList.remove('mm-show');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.remove('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.remove('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.remove('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.remove('mm-active');
            }
          }
        }
      }
    }
  };


  /**
   * Toggles the menu items
   */
  activateMenuItems() {

    const links = document.getElementsByClassName('nav-link-ref');

    let menuItemEl = null;


    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal


      // if (window.location.pathname === links[i]['pathname']) { //path location Statergy
      if (window.location.href === links[i]['href']) { //hash location statergy
        menuItemEl = links[i];

        break;
      }
    }

    if (menuItemEl) {
      menuItemEl.classList.add('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.add('mm-active');

        const parent2El = parentEl.parentElement;
        if (parent2El) {
          parent2El.classList.add('mm-show');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.add('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.add('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.add('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.add('mm-active');
            }
          }
        }
      }
    }
  }

}