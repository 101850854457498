import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IPServiceService {

  constructor(
    private http:HttpClient
  ) { }

  public currentIpAddress : Subject<any> = new Subject()
  
  currentIp;

  getIP(){
    this.http.get("http://api.ipify.org/?format=json").subscribe(data=>{
      this.currentIp = data;
    })
  }


  initService() {
    const conn = (navigator as any).connection;
    if (conn) {
      if (conn.saveData) {
      }
      const connectionlist = ["slow-2g", "2g", "3g", "4g"];
      const effectiveType = conn.effectiveType;
    }
  }

}
