import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';

import swal from 'sweetalert2'

@Component({
  selector: 'app-change-password-admin',
  templateUrl: './change-password-admin.component.html',
  styleUrls: ['./change-password-admin.component.scss']
})
export class ChangePasswordAdminComponent implements OnInit {

  @ViewChild('resetPasswordModal') resetModal: ElementRef


  constructor(
    private modalsService: NgbModal,
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) { }

  adminPasswordForm: FormGroup;
  passwordResetForm: FormGroup

  ngOnInit(): void {
    this.adminPasswordForm = this.formBuilder.group({
      adminPassword: ['', Validators.required]
    })

    this.passwordResetForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    })

  }


  /****************************************************************
   * Reset Password 
   */
  currentPage = 1;
  selectedUser;
  resetPasswordModalOpen(item) {
    this.currentPage = 1;
    this.selectedUser = item;
    this.adminPasswordForm.reset();
    this.passwordResetForm.reset();
    this.modalsService.open(this.resetModal, {
      centered: true,
      backdrop: 'static',
      size: 'md'
    })
  }


  /*******************************************
   * Submit Admin Password...
   */
  adminVerifyResponse;
  submitAdminPassword() {
    const value = {
      password: this.adminPasswordForm.value.adminPassword
    }
    this.authService.verifyAdminPassword(value).subscribe(
      data => {
        this.adminVerifyResponse = data;
        if (this.adminVerifyResponse.message == 'Valid Password') {
          this.currentPage = 2
          swal.fire(
            { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: 'Successfully Verified', icon: 'success' }
          )
        } else {
          swal.fire(
            { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: this.adminVerifyResponse.message, icon: 'error' }
          )
        }
      },
      err => {
        swal.fire(
          { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: 'Something went wrong ... ', icon: 'error' }
        )
      }
    )
  }


  /*************************************************
   * Reset Password ....
   */
  updateButtonText = "UPDATE"
  resetPasswordSubmit() {

    if (this.passwordResetForm.value.password == this.passwordResetForm.value.confirmPassword) {
      this.updateButtonText = "PROCESSING...";

      const value = {
        user_id: this.selectedUser.us_id,
        password: this.passwordResetForm.value.password
      }

      this.authService.setNewPasswordToUser(value).subscribe(
        data => {
          this.updateButtonText = "UPDATE"
          swal.fire(
            { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: 'Updated Successfully', icon: 'success' }
          )
          this.modalsService.dismissAll()
        },err=>{
          this.updateButtonText = "UPDATE";
          swal.fire(
            { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: 'Something went wrong...', icon: 'error' }
          )
        }
      )
    } else {
      swal.fire(
        { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: 'Password and confirm password does not match...', icon: 'error' }
      )
    }
  }
}
