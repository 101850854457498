import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatService } from 'src/app/services/dateFormat/date-format.service';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  @Output()
  emitDate: EventEmitter<any> = new EventEmitter<any>()


  constructor(
    private dateFormatterService: DateFormatService,
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private dateConfig: NgbDatepickerConfig,
  ) {
    dateConfig.minDate = { year: 1900, month: 1, day: 1 };
    dateConfig.maxDate = this.calendar.getToday();
   }

  fromDateSelected = "null";
  todateSelected = "null";
  enddate
  startDate
  days;
  // dateRangeDownload;
  // isAdmin = '"Admin"';
  // usertype;


  onDateSelection(date: NgbDate, datepicker) {
    this.fromDateSelected = "";
    this.todateSelected = "null";
    var dateRange;
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.toDate != null) {
      dateRange = this.dateFormatterService.formatFromToDate(this.fromDate, this.toDate)
      this.enddate = new Date(this.toDate.year, this.toDate.month, this.toDate.day);
      this.startDate = new Date(this.fromDate.year, this.fromDate.month, this.fromDate.day);
      this.days = Math.floor((Date.UTC(this.enddate.getFullYear(), this.enddate.getMonth(), this.enddate.getDate()) - Date.UTC(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate())) / (1000 * 60 * 60 * 24));

      if (this.days > 30) {
        this.emitDate.emit(dateRange)
        //this.getCreditStockReport(dateRange.fromDate, dateRange.toDate)
      } else {
        const value = {
          fromDate: dateRange.fromDate,
          toDate: dateRange.toDate
        }
        this.emitDate.emit(value)
        //this.getCreditStockReport(dateRange.fromDate, dateRange.toDate)
      }
    } else {
      dateRange = this.dateFormatterService.formatFromToDate(this.fromDate, this.fromDate);
      this.emitDate.emit(dateRange)
      //this.getCreditStockReport(dateRange.fromDate, dateRange.toDate)
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  ngOnInit(): void {
    this.getTodayTransactions()
  }

  /************************************
   * getLast10DaysTransaction ....
   */
  getLastTenDays() {
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 10)
    this.toDate = this.calendar.getToday();
    var date = this.dateFormatterService.formatFromToDate(this.fromDate, this.toDate);
    this.emitDate.emit(date)
  }

  /*************************************************************************
 * get yesterdays Reports ... 
 */
   getYesterdayReport() {
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 1)
    this.toDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 1)
    var date = this.dateFormatterService.formatFromToDate(this.fromDate, this.toDate);
    this.emitDate.emit(date)
  }

  /**************************************
   * GET todays transaction .......
   */
  getTodayTransactions() {
    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getToday();
    var date = this.dateFormatterService.formatFromToDate(this.fromDate, this.toDate);
    this.emitDate.emit(date)
  }

  /****************************************
   * GET last week ......
   */
  getThisWeekTransactions() {
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 7)
    this.toDate = this.calendar.getToday();
    var date = this.dateFormatterService.formatFromToDate(this.fromDate, this.toDate);
    this.emitDate.emit(date)
  }

  /**************
   * GET last month ...............
   */
  getThisMonthTransactions() {
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'm', 1)
    this.toDate = this.calendar.getToday();
    var date = this.dateFormatterService.formatFromToDate(this.fromDate, this.toDate);
    this.emitDate.emit(date)
  }

}
