import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendMoneyService } from 'src/app/services/money-transfer/send-money.service';
import { WizardComponent as BaseWizardComponent } from 'angular-archwizard';

import swal from 'sweetalert2'

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  loading = false;

  constructor(private router: Router, private modalService: NgbModal, private formBuilder: FormBuilder, private newService: SendMoneyService) { }

  @ViewChild("userTypeModal") private userTypeModal: TemplateRef<any>;
  @ViewChild('wizardForm') wizard: BaseWizardComponent;

  regiTypePage = 'perBusSection';

  personalDetails: FormGroup;
  businessDetails: FormGroup;
  bankDetails: FormGroup;
  acceptForm: FormGroup;
  phoneNumberForm: FormGroup;

  emiratesId: { key: string, value: string }[] = [];
  passport: { key: string, value: string }[] = [];
  visaCopy: { key: string, value: string }[] = [];

  OTPSent = false;


  ngOnInit(): void {

    this.emiratesId.push({ key: "Test", value: "test" })
    this.passport.push({ key: "Test", value: "test" })
    this.visaCopy.push({ key: "Test", value: "test" })


    this.phoneNumberForm = this.formBuilder.group({
      phoneNumber: ['', Validators.required]
    })

    this.personalDetails = this.formBuilder.group({
      fullName: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      gender: ['', Validators.required],
      nationality: ['', Validators.required]
    })

    this.businessDetails = this.formBuilder.group({
      businessCategory: ['', Validators.required],
      businessAddress: ['', Validators.required],
      landmark: ['', Validators.required],
      pincode: ['', Validators.required],
      city: ['', Validators.required]
    })

    this.bankDetails = this.formBuilder.group({
      holderName: ['', Validators.required],
      bank: ['', Validators.required],
      accountNumber: ['', Validators.required],
      branchName: ['', Validators.required],
      demo1: ['', Validators.required],
      demo2: ['', Validators.required]
    })
  }

  onRegister(e) {

    e.preventDefault();
    localStorage.setItem('isLoggedin', 'true');
    if (localStorage.getItem('isLoggedin')) {
      this.router.navigate(['/']);
    }
  }

  settings;
  phoneNumber;
  OTP

  sendOtp() {
    this.settings = {
      length: 6,
      numbersOnly: true,
      timer: 12
    }

    this.phoneNumber = this.phoneNumberForm.value.phoneNumber;
    const value = {
      number: this.phoneNumberForm.value.phoneNumber
    }
    this.OTP = this.newService.otpGenerator(value)
    alert(this.OTP + "" + "your OTP")
    this.OTPSent = true;
    this.phoneNumberForm.controls['phoneNumber'].disable();
  }


  OTP_from_User
  //resend otp------------

  onInputChange(e) {
    if (e.length == this.settings.length) {
      this.OTP_from_User = e;

      if (e == this.OTP) {
        alert("Success")
        this.wizard.navigation.goToNextStep();
      } else {
        alert("fail")
      }

    } else if (e == -1) {
    } else if (e == -2) {
      alert("resend otp?")
      this.sendOtp()
    }
  }

  backToLogin() {
    this.modalService.dismissAll();
    this.router.navigate(['/auth/login'])
  }

  addEmirats = true;
  deleteEmirates = true;

  addVisaCopy = true;
  deleteVisaCopy = true;

  addPassport = true;
  deletePassport = true;

  addField(section) {
    if (section == 'emirates_id') {
      if (this.emiratesId.length < 3) {
        this.emiratesId.push({
          key: "key",
          value: "value"
        })
        this.deleteEmirates = true;
      } else {
        this.addEmirats = false
      }
    }
    if (section == 'passport') {
      if (this.passport.length < 3) {
        this.passport.push({
          key: "key",
          value: "value"
        })
        this.deletePassport = true;
      } else {
        this.addPassport = false
      }

    }
    if (section == 'visa_copy') {
      if (this.visaCopy.length < 3) {
        this.visaCopy.push({
          key: "key",
          value: "value"
        })
        this.deleteVisaCopy = true;
      } else {
        this.addVisaCopy = false
      }
    }

  }

  deleteField(section) {
    if (section == 'emirates_id') {
      if (this.emiratesId.length > 1) {
        // this.emiratesId.slice(1,1);
        this.emiratesId.pop()
        this.addEmirats = true;
      } else {
        this.deleteEmirates = false;
      }
    }
    if (section == 'visa_copy') {
      if (this.visaCopy.length > 1) {
        // this.emiratesId.slice(1,1);
        this.visaCopy.pop()
        this.addVisaCopy = true;
      } else {
        // this.deleteEmirates = false;
      }
    }
    if (section == 'passport') {
      if (this.passport.length > 1) {
        // this.emiratesId.slice(1,1);
        this.passport.pop()
        this.addPassport = true;
      } else {
        this.deletePassport = false;
      }
    }
  }


  stepOneSubmit(data, section) {
    if (data == 'business') {
      this.regiTypePage = section
    }
    if (data == 'personal') {
      this.regiTypePage = section;
      this.modalService.dismissAll()
    }

  }


  handleFileInput(event) {

  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.querySelector("#cropperImageUpload") as HTMLElement;
    element.click()
  }

  registrationSubmit() {
    swal.fire(
      { title: 'Registration Success', text: 'Waiting for Approval', icon: 'success' }
    ).then((confirm) => {
      if (confirm.isConfirmed) {
        this.router.navigate(['/auth/login'])
        //alert("cancel")
      } else {
        // this.wizardForm.goToStep(0)
        //alert("ok")
      }
    })
  }

}
