import { Label } from 'ng2-charts';
import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Main',
    isTitle: true,
    permissionCode: '',
    status: ''
  },
  {
    label: 'Dashboard',
    icon: 'home',
    link: '/dashboard',
    permissionCode: 'DASHBOARD',
    status: ''
  },
  {
    label: 'Quick Links',
    icon: 'link',
    link: '/home/quick-links',
    permissionCode: 'HOME',
    status: ''
  },


  // ..........

  {
    label: 'PAYMENTO',
    isTitle: true,
    permissionCode: 'INRPAY',
    status: ''
  },

  {
    label: 'User Type',
    icon: 'user',
    permissionCode: 'USER_TYPE',
    status: '',
    subItems: [
      {
        label: 'Create',
        link: '/user-type/company/create',
        status: '',
        permissionCode: 'CREATE_USERTYPE'
      },
      {
        label: 'List',
        link: '/user-type/company/list',
        status: '',
        permissionCode: 'USERSTYPE_LIST'
      },
    ]
  },

  {
    label: 'Manage User',
    icon: 'pen-tool',
    permissionCode: 'USER',
    status: '',
    subItems: [
      // {
      //   label: 'Company User',
      //   subItems: [
      //     {
      //       label: 'Create',
      //       link: '/manage-user/create'
      //     },
      //     {
      //       label: 'List',
      //       link: '/manage-user/list'
      //     },
      //   ]
      // },
      // {
      //   label: 'Customer User',
      //   subItems: [
      {
        label: 'Create',
        link: '/manage-user/create-customer',
        status: '',
        permissionCode: 'CREATE_USER'
      },
      {
        label: 'List',
        link: '/manage-user/view-customer',
        status: '',
        permissionCode: 'USERS_LIST'
      },
      {
        label: 'User Stock Transfer',
        link: '/manage-user/userstocktransfer',
        status: '',
        permissionCode: 'USERSTOCK'
      },

    ]
  },
  {
    label: 'Vendors',
    icon: 'users',
    permissionCode: 'VENDORS',
    status: '',
    subItems: [
      {
        label: 'Vendors List',
        link: '/vendor/list',
        status: '',
        permissionCode: 'VENDORS_LIST'
      },
      // {
      //   label: 'Assign',
      //   link: '/vendor/assign'
      // },
    ]
  },
  {
    label: 'Tickets',
    icon: 'server',
    permissionCode: '',
    status: '',
    subItems: [
      {
        label: 'Tickets List',
        link: '/tickets/list',
        status: '',
        permissionCode: ''
      },
      {
        label: 'Priority Management',
        link: '/tickets/priority-management',
        status: '',
        permissionCode: ''
      },
      {
        label: 'Category Priority',
        link: '/tickets/category-priority',
        status: '',
        permissionCode: ''
      },
    ]
  },

  {
    label: 'Commission',
    icon: 'shopping-bag',
    permissionCode: 'COMMISSION',
    status: '',
    subItems: [
      {
        label: 'Commission Profile',
        link: '/commission/commission-profile',
        status: '',
        permissionCode: 'COMM_PROFILE'
      },
      {
        label: 'Slabs',
        link: '/commission/slabs',
        status: '',
        permissionCode: 'SLABS'
      }
    ]
  },
  {
    label: 'Wallet',
    icon: 'credit-card',
    permissionCode: 'WALLET',
    status: '',
    subItems: [
      {
        label: 'Wallet',
        link: '/wallet',
        status: '',
        permissionCode: 'WALLET'
      }
    ]
  },
  {
    label: 'Payout',
    icon: 'send',
    permissionCode: 'PAYOUTS',
    status: '',
    subItems: [
      {
        label: 'Payout',
        link: '/payouts/home',
        status: '',
        permissionCode: 'PAYOUTS'
      }
    ]
  },
  {
    label: 'DMT',
    permissionCode: 'MONEY_TRANSFER',
    icon: 'send',
    status: '',
    subItems: [
      {
        label: 'Send Money',
        link: '/money-transfer/send-money',
        status: '',
        permissionCode: 'MONEY_TRANSFER'
      },
      // {
      //   label: 'Withdraw',
      //   link: '/money-transfer/withdraw-money',
      //   status:'',
      //   permissionCode:'WITHDRAW'
      // },
      // {
      //   label: 'Balance',
      //   link: '/manage-user/create-customer',
      //   status:'',
      //   permissionCode:'BALANCE'
      // },
      // {
      //   label: 'Mini Statement',
      //   link: '/manage-user/create-customer',
      //   status:'',
      //   permissionCode:'MINI_STATEMENT'
      // },
    ]
  },
  // {
  //   label: 'WITHDRAW',
  //   permissionCode:'WITHDRAW',
  //   icon:'send',
  //   status:'',
  //   subItems: [

  //     {
  //       label: 'AEPS',
  //       link: '',
  //       status:'',
  //       permissionCode:'WITHDRAW'
  //     },
  //   ]
  // },
  {
    label: 'Recharge',
    icon: 'phone-call',
    permissionCode: 'RECHARGE',
    status: '',
    subItems: [
      {
        label: 'Prepaid Mobile',
        link: '/recharge/prepaid-mobile',
        status: '',
        permissionCode: 'MOBILE_RECHARGE'
      },
      {
        label: 'DTH',
        link: '/recharge/dth',
        status: '',
        permissionCode: 'DTH_RECHARGE'
      },
      {
        label: 'Recharge Plan',
        link: '/recharge/rechargeplans',
        status: '',
        permissionCode: 'RECHARGEPLAN'
      },
    ]
  },

  // {
  //   label: 'Bill Payments',
  //   icon: 'zap',
  //   permissionCode: 'BILLS',
  //   status: '',
  //   subItems: [
  //     {
  //       label: 'Complaints',
  //       link: '/bill-payments/complaints',
  //       status: '',
  //       permissionCode: 'BILLS'
  //     },
  //     {
  //       label: 'Complaints List',
  //       link: '/bill-payments/complaints-list',
  //       status: '',
  //       permissionCode: 'BILLS'
  //     }
  //   ]
  // },

  {
    label: 'Bill Payments',
    icon: 'zap',
    permissionCode: 'BILLS',
    status: '',
    subItems: [
      {
        label: 'Electricity',
        link: '/bill-payments/bbps/1',
        status: '',
        permissionCode: 'BILLS'
      },
      {
        label: 'Water',
        link: '/bill-payments/bbps/19',
        status: '',
        permissionCode: 'BILLS'
      },
      {
        label: 'Gas Piped Line',
        link: '/bill-payments/bbps/14',
        status: '',
        permissionCode: 'BILLS'
      },
      {
        label: 'Insurance',
        link: '/bill-payments/bbps/3',
        status: '',
        permissionCode: 'BILLS'
      },
      {
        label: 'LPG Booking',
        link: '/bill-payments/bbps/27',
        status: '',
        permissionCode: 'BILLS'
      },

      {
        label: 'BroadBand PostPaid',
        link: '/bill-payments/bbps/21',
        status: '',
        permissionCode: 'BILLS'
      },
      {
        label: 'LandLine PostPaid',
        link: '/bill-payments/bbps/18',
        status: '',
        permissionCode: 'BILLS'
      },
      {
        label: 'Loan Repayment',
        link: '/bill-payments/bbps/28',
        status: '',
        permissionCode: 'BILLS'
      },
      {
        label: 'Municipal Taxes',
        link: '/bill-payments/bbps/35',
        status: '',
        permissionCode: 'BILLS'
      },
      {
        label: 'Municipal Services',
        link: '/bill-payments/bbps/36',
        status: '',
        permissionCode: 'BILLS'
      },
      {
        label: 'Education',
        link: '/bill-payments/bbps/33',
        status: '',
        permissionCode: 'BILLS'
      },
      {
        label: 'Housing Society',
        link: '/bill-payments/bbps/34',
        status: '',
        permissionCode: 'BILLS'
      },
      {
        label: 'Cable TV',
        link: '/bill-payments/bbps/32',
        status: '',
        permissionCode: 'BILLS'
      },

      // {
      //   label: 'Housing Society',
      //   link: '/bill-payments/bbps/34',
      //   status: '',
      //   permissionCode: 'BILLS'
      // },
      {
        label: 'Fastag',
        link: '/bill-payments/bbps/30',
        status: '',
        permissionCode: 'BILLS'
      },
      {
        label: 'Life Insurance',
        link: '/bill-payments/bbps/29',
        status: '',
        permissionCode: 'BILLS'
      },

      {
        label: 'Credit Card',
        link: '/bill-payments/bbps/26',
        status: '',
        permissionCode: 'BILLS'
      },
      {
        label: 'Hospital',
        link: '/bill-payments/bbps/37',
        status: '',
        permissionCode: 'BILLS'
      },
      {
        label: 'Hospital and Pathology',
        link: '/bill-payments/bbps/23',
        status: '',
        permissionCode: 'BILLS'
      },


      {
        label: 'Subscription',
        link: '/bill-payments/bbps/39',
        status: '',
        permissionCode: 'BILLS'
      },
      {
        label: 'Mobile Postpaid',
        link: '/bill-payments/bbps/17',
        status: '',
        permissionCode: 'BILLS'
      },
      {
        label: 'Clubs And Associations',
        link: '/bill-payments/bbps/22',
        status: '',
        permissionCode: 'BILLS'
      },



      // {
      //   label: 'Complaints',
      //   link: '/bill-payments/complaints',
      //   status: '',
      //   permissionCode: 'BILLS'
      // },
      // {
      //   label: 'Query Transaction',
      //   link: '/bill-payments/query-transaction',
      //   status: '',
      //   permissionCode: 'BILLS'

      // },
      // {
      //   label: 'Complaints List',
      //   link: '/bill-payments/complaints-list',
      //   status: '',
      //   permissionCode: 'BILLS'
      // }
    ]
  },
  {
    label: 'Travel And Booking',
    icon: 'map-pin',
    permissionCode: 'TRAVEL_BOOKING',
    status: '',
    subItems: [
      {
        label: 'Sell',
        link: '/travel-and-booking/sell',
        status: '',
        permissionCode: 'SELL_TICKETS'
      },
      {
        label: 'Buy',
        link: '/travel-and-booking/buy',
        status: '',
        permissionCode: 'BUY_TICKETS'
      },
      {
        label: 'Manage',
        link: '/travel-and-booking/manage-booking',
        status: '',
        permissionCode: 'TRAVEL_MANAGE_USER'
      },


      {
        label: 'Manage',
        link: '/travel-admin/manage-booking',
        status: '',
        permissionCode: 'TRAVEL_MANAGE_ADMIN'
      },
      {
        label: 'Country',
        link: '/travel-admin/country',
        status: '',
        permissionCode: 'TRAVEL_BASE'
      },
      {
        label: 'Airline',
        link: '/travel-admin/airline',
        status: '',
        permissionCode: 'TRAVEL_BASE'
      },
      {
        label: 'Flight',
        link: '/travel-admin/flight',
        status: '',
        permissionCode: 'TRAVEL_BASE'
      },
      {
        label: 'flight-category',
        link: '/travel-admin/flight-category',
        status: '',
        permissionCode: 'TRAVEL_BASE'
      },
      // {
      //   label: 'Bulk Tickets',
      //   subItems: [

      //     {
      //       label: 'Sell',
      //       link: '/travel-and-booking/sell',
      //       status: '',
      //       permissionCode: 'SELL_TICKETS'
      //     },
      //     {
      //       label: 'Buy',
      //       link: '/travel-and-booking/buy',
      //       status: '',
      //       permissionCode: 'BUY_TICKETS'
      //     },
      //     {
      //       label: 'Manage',
      //       link: '/travel-and-booking/manage-booking',
      //       status: '',
      //       permissionCode: 'TRAVEL_MANAGE_USER'
      //     },


      //     {
      //       label: 'Manage',
      //       link: '/travel-admin/manage-booking',
      //       status: '',
      //       permissionCode: 'TRAVEL_MANAGE_ADMIN'
      //     },
      //     {
      //       label: 'Country',
      //       link: '/travel-admin/country',
      //       status: '',
      //       permissionCode: 'TRAVEL_BASE'
      //     },
      //     {
      //       label: 'Airline',
      //       link: '/travel-admin/airline',
      //       status: '',
      //       permissionCode: 'TRAVEL_BASE'
      //     },
      //     {
      //       label: 'Flight',
      //       link: '/travel-admin/flight',
      //       status: '',
      //       permissionCode: 'TRAVEL_BASE'
      //     },
      //     {
      //       label: 'flight-category',
      //       link: '/travel-admin/flight-category',
      //       status: '',
      //       permissionCode: 'TRAVEL_BASE'
      //     },

      //   ]
      // },
    ]
  },

  {
    label: 'Travel And Booking',
    icon: 'map-pin',
    permissionCode: 'TRAVEL_BOOKING_ADMIN_a',
    status: '',
    subItems: [
      {
        label: 'Bulk Tickets',
        subItems: [
          // {
          //   label: 'Country',
          //   link: '/travel-admin/country'
          // },
          // {
          //   label:'Airline',
          //   link:'/travel-admin/airline'
          // },
          {
            label: 'Manage',
            link: '/travel-admin/manage-booking'
          },
        ]
      },

      {
        label: 'Data Management',
        subItems: [
          {
            label: 'Country',
            link: '/travel-admin/country'
          },
          {
            label: 'Airline',
            link: '/travel-admin/airline'
          },
          {
            label: 'Flight',
            link: '/travel-admin/flight'
          },
          {
            label: 'flight-category',
            link: '/travel-admin/flight-category'
          },
          // {
          //   label: 'Airport',
          //   link: '/travel-admin/airport',
          // }
        ]
      }
    ]
  },


  // {
  //   label: 'Bill Payments',
  //   icon: 'credit-card',
  //   permissionCode: 'BILLS',
  //   status: '',
  //   subItems: [
  //     {
  //       label: 'Electricity',
  //       link: '/bill-payments/bbps/1',
  //       status: '',
  //       permissionCode: 'BILLS'
  //     },
  //     {
  //       label: 'Water',
  //       link: '/bill-payments/bbps/8',
  //       status: '',
  //       permissionCode: 'BILLS'
  //     },
  //     {
  //       label: 'Gas Piped Line',
  //       link: '/bill-payments/bbps/4',
  //       status: '',
  //       permissionCode: 'BILLS'
  //     },
  //     {
  //       label: 'Insurance',
  //       link: '/bill-payments/bbps/2',
  //       status: '',
  //       permissionCode: 'BILLS'
  //     },
  //     {
  //       label: 'LPG Booking',
  //       link: '/bill-payments/bbps/15',
  //       status: '',
  //       permissionCode: 'BILLS'
  //     }, ,
  //     {
  //       label: 'Complaints',
  //       link: '/bill-payments/bbps/1',
  //       status:'',
  //       permissionCode: 'BILLS'
  //     },
  //     {
  //       label: 'BroadBand PostPaid',
  //       link: '/bill-payments/bbps/10',
  //       status: '',
  //       permissionCode: 'BILLS'
  //     },
  //     {
  //       label: 'LandLine PostPaid',
  //       link: '/bill-payments/bbps/7',
  //       status: '',
  //       permissionCode: 'BILLS'
  //     },
  //     {
  //       label: 'Loan Repayment',
  //       link: '/bill-payments/bbps/12',
  //       status: '',
  //       permissionCode: 'BILLS'
  //     },
  //     {
  //       label: 'Municipal Taxes',
  //       link: '/bill-payments/bbps/16',
  //       status: '',
  //       permissionCode: 'BILLS'
  //     },
  //     {
  //       label: 'Education',
  //       link: '/bill-payments/bbps/17',
  //       status: '',
  //       permissionCode: 'BILLS'
  //     },
  //     {
  //       label: 'Housing Society',
  //       link: '/bill-payments/bbps/18',
  //       status: '',
  //       permissionCode: 'BILLS'
  //     },
  //     {
  //       label: 'Cable TV',
  //       link: '/bill-payments/bbps/21',
  //       status: '',
  //       permissionCode: 'BILLS'
  //     },
  //   ]
  // },

  // {
  //   label: 'AEPS',
  //   subItems: [
  //     {
  //       label: 'Adhar Enabled Payment System',
  //       link: '/manage-user/create-customer'
  //     },
  //   ]
  // },
  {
    label: 'IMT',
    permissionCode: 'IMT',
    status: '',
    subItems: [
      {
        label: 'IndoNepal',
        link: '/international-money-transfer/indo-nepal'
      },
    ]
  },
  {
    label: 'Bookings',
    permissionCode: 'BOOKINGS',
    status: '',
    subItems: [
      {
        label: 'Hotel',
        link: '/manage-user/create-customer',
        status: ''
      },
      {
        label: 'Air',
        link: '/manage-user/create-customer',
        status: ''
      },
    ]
  },

  {
    label: 'Check Status',
    icon: 'clock',
    permissionCode: 'CHECK_STAT',
    status: '',
    subItems: [
      {
        label: 'DMT Check status',
        link: '/reports/dmt-transactions',
        status: '',
        permissionCode: 'DMT_TRANS'
      },
      {
        label: 'Mobile Recharge',
        link: '/reports/mobile-status',
        status: '',
        permissionCode: 'PREPAID_STAT'
      },
      {
        label: 'DTH Recharge',
        link: '/reports/dth-status',
        status: '',
        permissionCode: 'DTH_STATUS'
      },
      {
        label: 'BBPS Status',
        link: '/reports/bbps-status',
        status: '',
        permissionCode: 'BBPS_STATUS'

      },
      {
        label: 'Transaction Refund',
        link: '/reports/transactionrefund',
        status: '',
        permissionCode: 'TRANSACTIONREFUND'
      },
      {
        label: 'Userwise Check Status',
        link: '/reports/userwisecheckstatus',
        status: '',
        permissionCode: 'USERWISECHECKSTATUS'
      },
    ]
  },

  {
    label: 'Requests',
    icon: 'clock',
    permissionCode: 'REQUESTS',
    status: '',
    subItems: [
      {
        label: 'Credit',
        link: '/reports/credit-request',
        status: '',
        permissionCode: 'CRED_REQUEST'
      },
      {
        label: 'Stock Purchase',
        link: '/reports/stock-transfer',
        status: '',
        permissionCode: 'STOCK_TRANS'
      },
      {
        label: 'Cash Deposit',
        link: '/cash-deposit/cash-deposit',
        status: '',
        permissionCode: 'CASH_DEPOSIT'
      },
      {
        label: 'Credit Close',
        link: '/wallet/creditclose',
        status: '',
        permissionCode: 'CREDITCLOSE'
      },
    ]
  },

  {
    label: 'Accounts',
    icon: 'file-text',
    permissionCode: 'ACCOUNTS',
    status: '',
    subItems: [
      {
        label: 'Bank Remittance',
        link: '/accounts/bankremittance',
        status: '',
        permissionCode: 'BANKREMIT'
      },
      {
        label: 'Remittance Approval',
        link: '/accounts/remittanceapproval',
        status: '',
        permissionCode: 'REMITAPPROVAL'
      },
      {
        label: 'Transaction Reconcillation',
        link: '/accounts/transactionremittance',
        status: '',
        permissionCode: 'TRANSACTIONREMIT'
      },
      {
        label: 'Cash Collect',
        link: '/accounts/cashcollect',
        status: '',
        permissionCode: 'CASHCOLLECT'
      },
    ]
  },

  // ]

  {
    label: 'Reports',
    icon: 'file-text',
    permissionCode: 'REPORTS',
    status: '',
    subItems: [
      {
        label: 'Transaction Report',
        link: '/reports/transaction',
        status: '',
        permissionCode: 'TRANS_REPORT'
      },

      {
        label: 'Stock Transfer',
        link: '/reports/stock-transfer-report',
        status: '',
        permissionCode: 'STOCK_REPORT'
      },

      {
        label: 'Stock Purchase',
        link: '/reports/stock-purchase',
        status: '',
        permissionCode: 'STOCK_PURCHASE_REPORT'

      },

      {
        label: 'Credit Report',
        link: '/reports/credit',
        status: '',
        permissionCode: 'CREDIT_REPORT'
      },

      {
        label: 'Credit Report',
        link: '/reports/parent/credit',
        status: '',
        permissionCode: 'CREDIT_REPORT_PARENT'
      },


      {
        label: 'Usage Report',
        link: '/reports/usage-report',
        status: '',
        permissionCode: 'USAGE_REP'
      },
      {
        label: 'Usage Report',
        link: '/reports/usage',
        status: '',
        permissionCode: 'USAGE_REPORT_DISTRIBUTOR'
      },
      // {
      //   label: 'Transaction',
      //   link: '/reports/parent/transaction',
      //   status: '',
      //   permissionCode: 'TRANS_PARENT_REP'
      // },
      {
        label: 'Mobile Recharge Report',
        link: '/reports/mobile-recharge-report',
        status: '',
        permissionCode: 'MOBILE_REP'

      },
      {
        label: 'Transaction Report',
        link: '/reports/parent/transaction',
        status: '',
        permissionCode: 'TRANS_PARENT_REP'
      },
      {
        label: 'Transaction Report',
        link: '/reports/user/transaction',
        status: '',
        permissionCode: 'TRANS_USER_REP'
      },
      {
        label: 'KYC Report',
        link: '/reports/kyc',
        status: '',
        permissionCode: 'KYC_REP'
      },

      {
        label: 'Profit Report',
        link: '/reports/admin/profit',
        status: '',
        permissionCode: 'ADMIN_PROFIT'
      },



      {
        label: 'Wallet Report',
        link: '/reports/wallet-report',
        status: '',
        permissionCode: 'WALLET_REP'
      },

      {
        label: 'User Onboard',
        link: '/reports/onboard',
        status: '',
        permissionCode: 'USER_ONBOARD'
      },

      {
        label: 'Master Wallet Usage',
        link: '/reports/masterwallet-usage',
        status: '',
        permissionCode: 'MASTER_WALLET'
      },
      {
        label: 'Request and Response',
        link: '/reports/request-response',
        status: '',
        permissionCode: 'REQUEST_RESPONSE'
      },
      {
        label: 'Credit Usage',
        link: '/reports/credit-usage',
        status: '',
        permissionCode: 'CREDIT_USAGE'
      },
      {
        label: 'Refund Report',
        link: '/reports/refund-report',
        status: '',
        permissionCode: 'REFUND_REPORT'
      },
      {
        label: 'Failure Transaction',
        link: '/reports/failure-transaction',
        status: '',
        permissionCode: 'FAILURE_TRANSACTION'
      },

      {
        label: 'Vendor Summary',
        link: '/reports/vendor-summary',
        status: '',
        permissionCode: 'VENDOR_SUMMARY'
      },
      {
        label: 'Travel Booking',
        link: '/reports/travel',
        status: '',
        permissionCode: 'TRAVEL_REPORT'
      },
      {
        label: 'Reprint',
        link: '/reports/reprint',
        status: '',
        permissionCode: 'REPRINT_REPORT'
      },
      {
        label: 'Credit Transaction',
        link: '/reports/credit-transaction',
        status: '',
        permissionCode: 'CREDIT_TRANSACTION'
      },
      {
        label: 'Credit Transaction',
        link: '/reports/credit-distributor',
        status: '',
        permissionCode: 'CREDIT_DISTRIBUTOR'
      },
      {
        label: 'Credit Outstanding',
        link: '/reports/credit-outstanding',
        status: '',
        permissionCode: 'CREDIT_OUTSTANDING'
      },
      {
        label: 'Bank Remittance Report',
        link: '/reports/bankremittancereport',
        status: '',
        permissionCode: 'BANKREMREPORT'
      },
      {
        label: 'User Commission Report',
        link: '/reports/usercommission',
        status: '',
        permissionCode: 'USERCOMMISSION'
      },
      {
        label: 'Cash Collection Report',
        link: '/reports/cashcollection',
        status: '',
        permissionCode: 'CASHCOLLECTIONREPORT'
      },
      {
        label: 'Userwise Summary Report',
        link: '/reports/userwisesummaryreport',
        status: '',
        permissionCode: 'USERWISESUMMARY'
      },
      {
        label: 'Remittance Summary Report',
        link: '/reports/remittancesummaryreport',
        status: '',
        permissionCode: 'REMITTANCESUMMARY'
      },
      {
        label: 'B2B Commission Report',
        link: '/reports/b2bcommissionreport',
        status: '',
        permissionCode: 'B2BCOMMISSION'
      },
    ]
  },

  {
    label: 'SETTINGS',
    isTitle: true,
    permissionCode: 'SETTINGS',
    status: '',
  },

  {
    label: 'Settings',
    icon: 'settings',
    permissionCode: 'MENU_SETTINGS',
    status: '',
    subItems: [
      {
        label: 'Menu Settings',
        link: '/settings/menu-settings',
        status: '',
        permissionCode: 'MENU_SETT_'
      },
      {
        label: 'Block Users',
        link: '/settings/block-users',
        status: '',
        permissionCode: 'MENU_SETT'
      },
      {
        label: 'Page Permissions',
        link: '/settings/permission-pages',
        status: '',
        permissionCode: 'MENU_SETT'
      },
      {
        label: 'User Permissions',
        link: '/settings/user-permissions',
        status: '',
        permissionCode: 'MENU_SETT'
      },
      {
        label: 'Change Parent',
        link: '/settings/changeparent',
        status: '',
        permissionCode: 'CHANGEPARENT'
      },
      {
        label: 'User Permission Change',
        link: '/settings/userpermissionchange',
        status: '',
        permissionCode: 'USERPERMISSIONCHANGE'
      },
      {
        label: 'Commission Flag Updation',
        link: '/settings/commissionflagupdation',
        status: '',
        permissionCode: 'COMMISSIONFLAGUPDATE'
      },
      {
        label: 'Vendor Assign',
        link: '/settings/commissionvendorassign',
        status: '',
        permissionCode: 'VENDORASSIGN'
      },
      {
        label: 'User Customer Assign',
        link: '/settings/usercustomerassign',
        status: '',
        permissionCode: 'USERCUSTOMERASSIGN'
      },
    ]
  }

]
