import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class SendMoneyService {

  constructor(
    private http: HttpClient,
    private common: CommonService
  ) { }

  service_ip = this.common.service_ip_server;
  requestHeader = this.common.httpOptions

  NumberResponse;
  beneficiaries;


  /******************************************
   * Remitter Login .. . .. . . 
   */
  remitterLogin(value) {
    return this.http.post(this.service_ip + "verify/remitter", {
      "mobile": value.mobile
    }, this.requestHeader)
  }

  /******************************************
   * verify remitter
   */
  verifyRemitter(value) {
    return this.http.post(this.service_ip + "remitter/sendotp", {
      "mobile": value.mobile,
      "name": value.name,
    }, this.requestHeader)
  }

  /***********************************************
   * verify OTP ... 
   */
  verifyOTP(value) {
    return this.http.post(this.service_ip + "remitter/verifyOtp", {
      "mobile": value.mobile,
      "name": value.name,
      "type": value.type,
      "otp": value.otp,
      "request_id": value.request_id,
      "reference_no": value.reference_no,
      "remitter_id": value.remitter_id
    }, this.requestHeader)
  }

  /*******************************************************
   * GET all beneficiaries ..................
   */
  getAllBeneficiaries(value) {
    return this.http.post(this.service_ip + "beneficiary/view", {
      "mobile": value.mobile
    }, this.requestHeader)
  }

  /*****************************************************
   * Create Beneficiaries .........
   */
  createBeneficiaries(value) {
    return this.http.post(this.service_ip + "beneficiary", {
      "mobile": value.mobile,
      "name": value.name,
      "account_number": value.account_number,
      "ifsc": value.ifsc,
      "bank": value.bank_id,
      "account_holdername": value.account_holder_name,
      "branch_name": value.branch_name,
      "user_id": value.user_id,
      "remitter_id": value.remitter_id
    }, this.requestHeader)
  }

  /************************************************
   * Delete beneficiary
   */
  deleteBeneficiary(value) {
    return this.http.post(this.service_ip + "beneficiary/delete", {
      "beneficiaryId": value.bene_id,
      "mobile": value.mobile
    }, this.requestHeader)
  }

  /**************************************************
   * Money Transfer Service ................
   */
  sendMoney(value) {
    return this.http.post(this.service_ip + "dmt/sendmoney", {
      "beneficary_id": value.beneficiary_id,
      "remitter_id": value.remitter_id,
      "amt": value.amount,
      "token_id": value.token,
    }, this.requestHeader)
  }

  /**************************************************'
  * Account verification ...
  */
  accountVerification(value) {
    return this.http.post(this.service_ip + "account/validation", {
      "mobile": value.mobile,
      "account_no": value.account_no,
      "bank_code": value.bank_code
    }, this.requestHeader)
  }


  /***************************************************************
   * IFSC Search ... 
   */
  ifscSearch(value) {
    return this.http.post(this.service_ip + "get-bankbranch-ifsc", {
      "ifsc": value.ifsc
    }, this.requestHeader)
  }

  otpGenerator(value) {
    var digits = '0123456789';
    let OTP = '';
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP
  }


  getRemitterBalance(value) {
    return this.http.post(this.service_ip + "remitterBalance", {
      "mobile": value.mobile
    }, this.requestHeader)
  }

   /**************************************************
   * Money Transfer Service ................
   */
    generateToken(value) {
      return this.http.post(this.service_ip + "tsmtokengenerate", {
        "remitter_id": value.remitter_id,
        "beneficary_id": value.beneficiary_id,
        "amt": value.amount,
      }, this.requestHeader)
    }

}
