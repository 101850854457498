import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) { }


  service_ip = this.commonService.service_ip_server;
  requestHeader = this.commonService.httpOptions;


  /************************************************
 * Reset Password Phone Submit .... 
 */
  resetPasswordPhoneSubmit(value) {
    return this.http.post(this.service_ip + "change-password-sentotp", {
      "phone_number": value.mobile
    }, this.requestHeader)
  }

  /****************************************************************************
   * Admin : change password - verify admin password 
   */
  verifyAdminPassword(value) {
    return this.http.post(this.service_ip + "change-password-check", {
      "password": value.password
    }, this.requestHeader)
  }

  /*********************************************************************
   * Set New Password to User ... 
   */
  setNewPasswordToUser(value) {
    return this.http.post(this.service_ip + "change-password-user-admin", {
      "us_id": value.user_id,
      "password": value.password
    }, this.requestHeader)
  }


    /********************************************************
   * Send rest password
   */
     resetPasswordOtp(value) {
      return this.http.post(this.service_ip + "sendloginotp", {
        "mobile": value.mobile
      })
    }
  
    /*************************************************
     * Submit reset password OTP
     */
    submitResetPasswordOtp(value) {
      return this.http.post(this.service_ip + "verifyuserotp", {
        "otp_id":value.otpId,
        "otp":value.otp
      })
    }
  
    /**************************************
     * New Password submit .... 
     */
    resetPasswordSubmit(value) {
      return this.http.post(this.service_ip + "passwordreset", {
        "mobile": value.mobile,
        "password": value.password
      })
    }

}
