import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore'
import { Router } from '@angular/router';
// import { element } from 'protractor';
import { Subject } from 'rxjs';
import { userPermissions } from 'src/app/core/guard/user-permissions';
import { EncryptService } from '../encrypt/encrypt.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(
    private fireStore: AngularFirestore,
    private http: HttpClient,
    private router: Router,
    private encryptService: EncryptService
  ) { }

  userPermissionsNew;
  public userPermissionsSubject: Subject<any> = new Subject()

  getPermissionDetails() {
    // this.fireStore.collection('userPermissions').snapshotChanges().subscribe(
    //   data => {
    //     this.userPermissionsNew = data.map(e => {
    //       return {
    //         id: e.payload.doc.id,
    //         data: e.payload.doc.data()
    //       }
    //     })

    //   }
    //)

    // data=>{
    //   this.userPermissions = data;
    //   this.userPermissionsSubject.next(this.userPermissions)
    // }


    // this.http.get(this.baseUrl).subscribe(
    //   data => {

    //     this.userPermissionsNew = data;
    //     this.userPermissionsSubject.next(this.userPermissionsNew)
    //   }
    // )
    //return this.fireStore.collection('userPermissions').snapshotChanges()
  }

  loginedUser

  success = 0;
  completed = 0;

  userPermissions = userPermissions.permissions;

  hasPermission(route, state) {
    this.success = 0
    this.loginedUser = localStorage.getItem('user_type');
    if (route.data.page_name != undefined) {
      // if (this.userPermissions) {
      //   for (var i = 0; i < this.userPermissions.length; i++) {
      //     if (this.userPermissions[i].data.user_type == this.loginedUser) {
      //       for (var j = 0; j < this.userPermissions[i].data.permissions.length; j++) {
      //         if (this.userPermissions[i].data.permissions[j] == route.data.page_name) {
      //           //return true;
      //           this.success = 1;
      //           this.completed = 1
      //         }
      //       }
      //     }
      //   }
      // }
      var permissionString = this.encryptService.getDecryptedPermissions();
      var perArray = permissionString.split(',');
      var replacedArray = [];

      perArray.forEach(element => {
        element = element.replace(/['"]+/g, '');
        replacedArray.push(element)
      });

      if (replacedArray) {
        replacedArray.forEach(element => {
          if (element == route.data.page_name) {
            this.success = 1;
            this.completed = 1
          }
        })
      }

      // if(this.completed == 1){
      if (this.success == 1) {
        return true
      } else {
        // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        this.router.navigate(['/auth/login']);
        return false
      }
    } else {
      return true
    }
  }


  successChild = 0
  hasChildPermission(route, state) {
    this.successChild = 0

    var permissionString = this.encryptService.getDecryptedPermissions();
    var perArray = permissionString.split(',');
    var replacedArray = [];

    perArray.forEach(element => {
      element = element.replace(/['"]+/g, '');
      replacedArray.push(element)
    });

    this.userPermissions = userPermissions.permissions;



    this.loginedUser = localStorage.getItem('user_type');
    if (route.data.page_name != undefined) {
      // if (this.userPermissions) {
      //   for (var i = 0; i < this.userPermissions.length; i++) {
      //     if (this.userPermissions[i].data.user_type == this.loginedUser) {
      //       for (var j = 0; j < this.userPermissions[i].data.permissions.length; j++) {
      //         if (this.userPermissions[i].data.permissions[j] == route.data.page_name) {
      //           //return true;
      //           this.successChild = 1;
      //           this.completed = 1
      //         }
      //       }
      //     }
      //   }
      // }
      if (replacedArray) {
        replacedArray.forEach(element => {
          if (element == route.data.page_name) {
            this.successChild = 1;
            this.completed = 1
          }
        })
      }

      // if(this.completed == 1){
      if (this.successChild == 1) {
        return true
      } else {
        // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        this.router.navigate(['/auth/login']);
        return false
      }
    } else {
      return true
    }
  }

}
