import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-download-indicator',
  templateUrl: './download-indicator.component.html',
  styleUrls: ['./download-indicator.component.scss']
})
export class DownloadIndicatorComponent implements OnInit {

  constructor() { }

  progress = 0;

  ngOnInit(): void {
    // this.emitSuccessDownload.emit(30)
  }

  @Output()
  emitSuccessDownload: EventEmitter<any> = new EventEmitter<any>()

  setDownloadProgress(value){
    this.progress = value;
  }

}
