import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private common: CommonService,
    private router: Router
  ) { }

  service_ip = this.common.service_ip_server;
  requestHeader = this.common.httpOptions;

  
  /****************************************
   * Login Submit service ...
   */
  doLogin(value) {
    return this.http.post(this.service_ip + "login", {
      "email": value.username,
      "password": value.password
    })
  }

  /*******************************************
   * Reset Password ..
   */
  resetPassword(value) {
    return this.http.post(this.service_ip + "password/reset", {
      "password": value.password,
      "token": value.token,
      "password_confirmation": value.password
    })
  }


  /********************************************************
   * Send rest password
   */
  resetPasswordOtp(value) {
    return this.http.post(this.service_ip + "sendloginotp", {
      "mobile": value.mobile
    })
  }

  /*************************************************
   * Submit reset password OTP
   */
  submitResetPasswordOtp(value) {
    return this.http.post(this.service_ip + "verifyuserotp", {
      "otp_id": value.otpId,
      "otp": value.otp
    })
  }

  /**************************************
   * New Password submit .... 
   */
  resetPasswordSubmit(value) {
    return this.http.post(this.service_ip + "passwordreset", {
      "mobile": value.mobile,
      "password": value.password
    })
  }

  /******************************************
   * Logout service...
   */
  doLogout() {
    localStorage.removeItem('user_data');
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_type');
    localStorage.removeItem('status');
    localStorage.removeItem('permissions')
  }

  /************************************************
   * Reset Password Phone Submit .... 
   */
  resetPasswordPhoneSubmit(value) {
    return this.http.post(this.service_ip + "change-password-sentotp", {
      "phone_number": value.mobile
    }, this.requestHeader)
  }

  /****************************************************************************
   * Admin : change password - verify admin password 
   */
  verifyAdminPassword(value) {
    return this.http.post(this.service_ip + "change-password-check", {
      "password": value.password
    }, this.requestHeader)
  }

  /*********************************************************************
   * Set New Password to User ... 
   */
  setNewPasswordToUser(value) {
    return this.http.post(this.service_ip + "change-password-user-admin", {
      "us_id": value.user_id,
      "password": value.password
    }, this.requestHeader)
  }

}
