import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module';

import { LayoutModule } from './views/layout/layout.module';
import { AuthGuard, AuthGuardModule } from './core/guard/auth.guard';

import { AppComponent } from './app.component';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';

import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateUserComponent } from './views/inrPay/manage-user/create-user/create-user.component';
import { ArchwizardModule } from 'angular-archwizard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PdfHandlerComponent } from './InrPayCommon/pdf/pdf-handler/pdf-handler.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { RedirectService } from './services/redirect/redirect.service';
import { NewtabErrorComponent } from './views/pages/newtab-error/newtab-error.component';

// import { UserOnboardReportComponent } from './inrPay/Reports/user-onboard-report/user-onboard-report.component';
// import { DownloadComponent } from './services/download/download/download.component';
// import { StockTransferComponent } from './inrPay/recharges/Reports/stock-transfer/stock-transfer.component';

// import { BillConfirmComponent } from './InrPayCommon/bill-confirm/bill-confirm.component';
// import { BillFetchComponent } from './views/inrPay/common/bill-fetch/bill-fetch.component';
//import { ListComponent } from './views/worldRecharge/tickets/list/list.component';
//import { TimelineComponent } from './views/worldRecharge/tickets/timeline/timeline.component';
//import { VendorsListComponent } from './views/worldRecharge/vendor/vendors-list/vendors-list.component';
//import { UsertypeCreateComponent } from './worldRecharge/usertype/usertype-create/usertype-create.component';

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    NewtabErrorComponent,
    // UserOnboardReportComponent,
    //DownloadComponent,
    // StockTransferComponent,
    // BillFetchComponent,
    // BillConfirmComponent,
    //PdfHandlerComponent,
    //ListComponent,
    //TimelineComponent,
    //VendorsListComponent,
    // CreateUserComponent,
    //UsertypeCreateComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ArchwizardModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    //ArchwizardModule
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule
  ],
  providers: [
    AuthGuard,
    AuthGuardModule,
    RedirectService,
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        }
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
