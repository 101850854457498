import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { throwError } from 'rxjs';


// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/json',
//     'Authorization': "Bearer"+" "+localStorage.getItem("user_data")
//    })
// };

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer" + " " + localStorage.getItem("user_data"),
      'mode': 'no-cors',
    })
  };


  // service_ip = "https://api.npoint.io/c0668be4c901eb224c27/";   //npoint server

  service_ip_server = "https://api.paymento.in/api/";  //LIVE SEVER NEW
  //service_ip_server = "https://staging.paymento.in/api/"     //Staging
  // service_ip_server = 'https://inrpay.wrsolutions.in/api/'  //Live Server

  constructor() { }


  setHeaders() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer" + " " + localStorage.getItem("user_data"),
        'mode': 'no-cors',
      })
    };
  }




  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }


}
