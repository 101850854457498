import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) { }

  public userDetails: Subject<any> = new Subject();

  service_ip = this.commonService.service_ip_server;
  requestHeader = this.commonService.httpOptions;

  getUserDetails() {
    return this.http.post(this.service_ip + "getuserList", {}, this.requestHeader)
  }


  /****************************************
   * USER details subject ..
   */
  userDataResponse;
  setUserDetails() {
    this.http.post(this.service_ip + "getuserList", {}, this.requestHeader).subscribe(
      data => {
        this.userDataResponse = data;
        this.userDetails.next(this.userDataResponse)
      }
    )
  }

  /**************************************************************
   * Get all users List for fliter....
   */
  getAllUsersList() {
    return this.http.post(this.service_ip + "user_minimal_data", {}, this.requestHeader)
  }


  /****************************************************************
   * get all master parent users .. 
   */
  getAllMasterParentUsers() {
    return this.http.get(this.service_ip + "master-parent-list", this.requestHeader)
  }


  getAllbanks() {
    return this.http.get(this.service_ip + "getbank", this.requestHeader)
  }

  stocktransferApproval(remjson) {
    let body = JSON.stringify(remjson);
    //console.log("appr" + body);
    return this.http.post(this.service_ip + "user_stock_transfer", {
      "arr_value": body
    }, this.requestHeader);
  }

  getRechargePlans() {
    return this.http.post(this.service_ip + "viewmobilePlans", {}, this.requestHeader)
  }

  getFirstlevelUsers() {
    return this.http.get(this.service_ip + "first_level_userlist", this.requestHeader)
  }

  getUserlist() {
    return this.http.get(this.service_ip + "get_admin_userslist", this.requestHeader)
  }

  geCompanyUserlist() {
    return this.http.get(this.service_ip + "office_user", this.requestHeader)
  }

  geCustomerUserlist() {
    return this.http.get(this.service_ip + "getcustomeruserlist", this.requestHeader)
  }
}
