import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore'

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private fireStore:AngularFirestore
  ) { }

  walletUpdationId = "GSmIKlA2FXYGT0ouUAPc"

  getWalletDetails(){
    return this.fireStore.collection('wallet').snapshotChanges()
  }

  getWalletUpdateDetails(){
    return this.fireStore.collection('walletUpdation').snapshotChanges()
  }

  updateWallet(record){
    this.fireStore.doc('walletUpdation/' + this.walletUpdationId).update(record);
  }

}
