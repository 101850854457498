import { Component, OnInit, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';


import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DownloadService } from 'src/app/services/download/download.service';
import { EncryptService } from 'src/app/services/encrypt/encrypt.service';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit, OnDestroy {

  isLoading: boolean;

  @ViewChild('TimeoutModal') TimeoutModal: ElementRef;


  constructor(
    private router: Router,
    private permissionService: PermissionsService,
    private modalService: NgbModal,
    private idle: Idle, private keepalive: Keepalive,
    private authService: AuthService,
    private downloadService: DownloadService,
    private encryptService: EncryptService
  ) {

    // Spinner for lazyload modules
    router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isLoading = false;
      }
    });

    permissionService.getPermissionDetails()


    // sets an idle timeout 
    idle.setIdle(1800);
    // 1800
    // sets a timeout period 
    idle.setTimeout(10);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      //this.childModal.hide();
      this.modalService.dismissAll(this.TimeoutModal)
      this.idleState = 'Timed out!';
      this.timedOut = true;
      // authService.doLogout()
      this.router.navigate(['/auth/login'])
      setTimeout(() => {
        location.reload();
      }, 500);
      //this.router.navigate(['/']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      this.modalService.open(this.TimeoutModal, { centered: true, size: 'md' });
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());


    idle.watch()
    // this.authService.getUserLoggedIn().subscribe(userLoggedIn => {
    //   if (userLoggedIn) {
    //     idle.watch()
    //     this.timedOut = false;
    //   } else {
    //     idle.stop();
    //   }
    // })

  }

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  @ViewChild('newTabModal') modal: ElementRef

  ngOnInit(): void {
    this.downloadService.setDownloadDetails(0);

    // register_tab_GUID()
    // // multi tab detection
    // function register_tab_GUID() {
    //   // detect local storage available
    //   if (typeof (Storage) !== "undefined") {
    //     // get (set if not) tab GUID and store in tab session
    //     if (sessionStorage["tabGUID"] == null) sessionStorage["tabGUID"] = tab_GUID();
    //     var guid = sessionStorage["tabGUID"];

    //     // add eventlistener to local storage
    //     window.addEventListener("storage", storage_Handler, false);

    //     // set tab GUID in local storage
    //     localStorage["tabGUID"] = guid;
    //   }
    // }

    // function storage_Handler(e) {
    //   console.log("eeeeee ==== ",e)
    //   // if tabGUID does not match then more than one tab and GUID
    //   if (e.key == 'tabGUID') {
    //     if (e.oldValue != e.newValue) tab_Warning();
    //   }
    // }

    // function tab_GUID() {
    //   function s4() {
    //     return Math.floor((1 + Math.random()) * 0x10000)
    //       .toString(16)
    //       .substring(1);
    //   }
    //   return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    //     s4() + '-' + s4() + s4() + s4();
    // }

    // function tab_Warning() {
    //   alert("Another tab is open!");
    // }
    var usertype = this.encryptService.getDecryptedUserType();
    var isAdmin = '"Admin"';
    if (usertype != isAdmin) {
      if (sessionStorage.getItem('newSession')) {

      } else {
        this.router.navigate(['err'])
      }
    }
    //     // transfers sessionStorage from one tab to another
    // var sessionStorage_transfer = function(event) {
    //   if(!event) { event = window.event; } // ie suq
    //   if(!event.newValue) return;          // do nothing if no value to work with
    //   if (event.key == 'getSessionStorage') {
    //     // another tab asked for the sessionStorage -> send it
    //     localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
    //     // the other tab should now have it, so we're done with it.
    //     localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
    //   } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
    //     // another tab sent data <- get it
    //     var data = JSON.parse(event.newValue);
    //     for (var key in data) {
    //       sessionStorage.setItem(key, data[key]);
    //     }
    //   }
    // };

    // // listen for changes to localStorage
    // if(window.addEventListener) {
    //   window.addEventListener("storage", sessionStorage_transfer, false);
    // } else {
    //   window.attachEvent("onstorage", sessionStorage_transfer);
    // };


    // // Ask other tabs for session storage (this is ONLY to trigger event)
    // if (!sessionStorage.length) {
    //   localStorage.setItem('getSessionStorage', 'foobar');
    //   localStorage.removeItem('getSessionStorage');
    // };


    //this.initService();
    // this.register_tab_GUID()

  }


  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.modalService.dismissAll(this.TimeoutModal);
    this.timedOut = false;
  }

  hideChildModal(): void {
    // this.childModal.hide();
    this.modalService.dismissAll(this.TimeoutModal)
  }

  stay() {
    //this.childModal.hide();
    this.modalService.dismissAll(this.TimeoutModal)
    this.reset();
  }

  logout() {
    this.modalService.dismissAll(this.TimeoutModal)
    //this.router.navigate(['/']);

    this.authService.doLogout();


  }



  // // multi tab detection
  // register_tab_GUID() {
  //   // detect local storage available
  //   if (typeof (Storage) !== "undefined") {
  //     // console.log("storage")
  //     // get (set if not) tab GUID and store in tab session
  //     if (sessionStorage["tabGUID"] == null) {
  //       // console.log("session hit");
  //       sessionStorage["tabGUID"] = this.tab_GUID();
  //     }
  //     var guid = sessionStorage["tabGUID"];

  //     // add eventlistener to local storage
  //     window.addEventListener("storage", this.storage_Handler, false);

  //     // set tab GUID in local storage
  //     localStorage["tabGUID"] = guid;
  //   }
  //   // console.log("session")
  // }

  // storage_Handler(e) {
  //   // console.log("storage handler")
  //   // if tabGUID does not match then more than one tab and GUID
  //   if (e.key == 'tabGUID') {
  //     console.log("e.key == ", e)
  //     if (e.oldValue != e.newValue) {
  //       // console.log("storage if ==")
  //       // this.tab_Warning();
  //       // this.newTest();
  //       console.log("new tab");
  //       alert("New Tab")
  //     }
  //   }
  // }

  // tab_GUID() {
  //   console.log("autogenerate");
  //   localStorage.clear()
  //   function s4() {
  //     return Math.floor((1 + Math.random()) * 0x10000)
  //       .toString(16)
  //       .substring(1);
  //   }
  //   return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
  //     s4() + '-' + s4() + s4() + s4();
  // }

  // tab_Warning() {
  //   // console.log("==Warning===")
  //   // alert("Another tab is open!");
  // }

  // newTest() {
  //   console.log("new test")
  // }



  ngOnDestroy() {
    //alert("hiiii")
  }

  //Modal popup.....................................................................
  //  FadeInAnimation(FadeInContent) {
  //   this.modalService.open(FadeInContent, { windowClass: 'animated pulse', size: 'xl',centered:true });
  //   }


  // @HostListener('window:focus', ['$event'])
  // onFocus(event: FocusEvent): void {

  //   // Do something 

  // }

}
