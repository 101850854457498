import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { IPServiceService } from './services/IPservice/ip-service.service';
import { NotificationService } from './services/Notification/notification.service';
//import {SwPush} from '@angular/service-worker';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { AuthService } from './services/auth/auth.service';
import { userPermissions } from '../app/core/guard/user-permissions'
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'world-recharge';

  constructor(
    private notificationService: NotificationService,
    private ipService: IPServiceService,
    private authService: AuthService,
    private permissionService: PermissionsService,
    private router: Router
    //private swPush:sw
  ) { }

  inputVar = 1
  i = 0;
  readonly VAPID_PUBLIC_KEY = "BO_qWSbCt2TScRonv1SSHF97I-VzwMWMkd5AtxHjb4-2npdC8S4tE7BjR-jOX6im7g36LtDeUZCmti3IkanYA_c"

  ngOnInit(): void {

    

      this.getPem()
    // this.getNotification();
    //this.getIP();
    //this.getPermissionsDetails()
    let context = this

    // window.addEventListener('beforeunload', function (e) {
    //   e.preventDefault();
    //   e.returnValue = '';
    //   alert("closing ... ")
    //   context.doLogout()

    //  });


    // window.addEventListener('unload', function(e) {
    //   e.preventDefault()
    //     // e.returnValue = '';
    //     // alert("closing ... "
    //  // alert("=====")
    // });

    // this.setPermissions()
  }




  doLogout() {
    this.authService.doLogout()
  }

  getIP() {
    this.ipService.getIP()
  }

  getPem() {
    // //setTimeout(() => {
    //   this.permissionService.getPermissionDetails();
    //   this.getPem()
    // //}, 1000);
  }




  //   subscribeToNotifications() {

  //     this.swPush.requestSubscription({
  //         serverPublicKey: this.VAPID_PUBLIC_KEY
  //     })
  //     .then(sub => this.newsletterService.addPushSubscriber(sub).subscribe())
  //     .catch(err => console.error("Could not subscribe to notifications", err));
  // }

  // getNotification() {
  //   this.notificationService.getNotification(this.i + 1)
  //   // setTimeout(() => {
  //   //   this.i=this.i+1
  //   //   this.getNotification()
  //   // }, 3000);
  // }

  // getPermissionsDetails(){
  //   this.permissionService.getPermissionDetails()
  // }



}
