import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WorldRechargeData } from '../../core/dummy-datas/world-recharge.data'
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor( 
    private common:CommonService,
    private http:HttpClient
  ) { }

}
