import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptService {

  plainText: string = "Vishnu mm";
  encryptText: string;
  encPassword: string = "yycbjcdhhjkjkjckdjkhvkhkvduiuenkncvkdhvhvnknvr.vrjirvnguhirjncv.cerjijofoejofejfff--cenihvihrnjnvgjnknbihijvnnivrojvonvonihvrojvr-vrvjvkrvnvjrvnhibhr--f==f==vrvrvrjbujvbribvirvbirnvirbviubrvibrvibrviubinkrnknrkihojrnkvhhihvoijrouojvokvijoivoklklmkvhkhkhjkcyijvknvkrbjrhiknndnsnkskfhkfkhfkfh.vrfnvhrivhinvjnrvuriirnvjbjbvrnvrknvrnvrnninvrovrovrokvronvonvonrvr____Vrvhrivrjbvjrbvr_VRV_RV_RV_RV_r-vrvnvrnvnvhjvhrirjojvrihvorhvhorvorvhohvrovhoihoihiohjojinhijfvhuhrvuirebvjbvuhruhjhwqnqyqqjmlxmqpwqqpqmsdskjlkalkojdl;jsoiwdxncuiugcenknvceihveuvknknveiohvrvonrvnrnipcbbx2882jb3j2b22b4hr4bjkbvjkdvf5566vr6b6brb8tb9t9bt5b5d6666ddvbbrfvbuibubvrubvrubevbubvrv--vrvnrivbrvr-rrvrv--vrvhjrvjvrjjivfvrjvihvirhviihuvuruihuihciushhcjnjnvrv..v.r.vr.v.rv.r.v.rv.rvuebiubuigwiugfuiewfyefewfjbjhbvhjbuivbbjibjniuguiwguigdcjkbkvjbjbvbevuirev_Vrvjrivrvvr-vr-v-vv-rv-_vrvrvrvhgygygugiufrefugiuguigvuifgvugfvbbvbvjnjvnjvnjnvjnjnvunuvruivninjqqwwqwqasasdfsfsaffasFDDFdfgftfDG";
  decPassword: string = "yycbjcdhhjkjkjckdjkhvkhkvduiuenkncvkdhvhvnknvr.vrjirvnguhirjncv.cerjijofoejofejfff--cenihvihrnjnvgjnknbihijvnnivrojvonvonihvrojvr-vrvjvkrvnvjrvnhibhr--f==f==vrvrvrjbujvbribvirvbirnvirbviubrvibrvibrviubinkrnknrkihojrnkvhhihvoijrouojvokvijoivoklklmkvhkhkhjkcyijvknvkrbjrhiknndnsnkskfhkfkhfkfh.vrfnvhrivhinvjnrvuriirnvjbjbvrnvrknvrnvrnninvrovrovrokvronvonvonrvr____Vrvhrivrjbvjrbvr_VRV_RV_RV_RV_r-vrvnvrnvnvhjvhrirjojvrihvorhvhorvorvhohvrovhoihoihiohjojinhijfvhuhrvuirebvjbvuhruhjhwqnqyqqjmlxmqpwqqpqmsdskjlkalkojdl;jsoiwdxncuiugcenknvceihveuvknknveiohvrvonrvnrnipcbbx2882jb3j2b22b4hr4bjkbvjkdvf5566vr6b6brb8tb9t9bt5b5d6666ddvbbrfvbuibubvrubvrubevbubvrv--vrvnrivbrvr-rrvrv--vrvhjrvjvrjjivfvrjvihvirhviihuvuruihuihciushhcjnjnvrv..v.r.vr.v.rv.r.v.rv.rvuebiubuigwiugfuiewfyefewfjbjhbvhjbuivbbjibjniuguiwguigdcjkbkvjbjbvbevuirev_Vrvjrivrvvr-vr-v-vv-rv-_vrvrvrvhgygygugiufrefugiuguigvuifgvugfvbbvbvjnjvnjvnjnvjnjnvunuvruivninjqqwwqwqasasdfsfsaffasFDDFdfgftfDG";
  conversionEncryptOutput: string;
  conversionDecryptOutput: string;

  unblockedString = '"unblocked"'
  blockedString = '"blocked"'

  constructor() { }

  /*****************************************************
   * Set encrypted status to local storage ... (blocked/unblocked)
   */
  setEncriptedStatus(status) {
    var userStatus;
    if (status == '1') {
      userStatus = 'unblocked'
    } else {
      userStatus = 'blocked'
    }
    this.conversionEncryptOutput = CryptoJS.AES.encrypt(JSON.stringify(userStatus).trim().toString(), this.encPassword.trim()).toString();
    localStorage.setItem('status', this.conversionEncryptOutput)
  }

  /*****************************************************
   * Get decrypted user status
   */
  getDecryptedStatus() {
    var status = localStorage.getItem('status');
    if (status != undefined) {
      this.conversionDecryptOutput = CryptoJS.AES.decrypt(status.trim().toString(), this.decPassword.trim()).toString(CryptoJS.enc.Utf8);
      return this.conversionDecryptOutput
    } else {
      return '"blocked"'
    }

  }

  /**********************************************
   * Set encrypted user type to local storage .. 
   */
  userTypeEncryptionOutput;
  setEncryptedUserType(usertype) {
    this.userTypeEncryptionOutput = CryptoJS.AES.encrypt(JSON.stringify(usertype).trim().toString(), this.encPassword.trim()).toString();
    localStorage.setItem('type', this.userTypeEncryptionOutput)
  }

  /***********************************************
   * Get decrypted usertype ... 
   */
  decryptedUserType;
  getDecryptedUserType() {
    var user_type = localStorage.getItem('type');
    if (user_type != undefined) {
      this.decryptedUserType = CryptoJS.AES.decrypt(user_type.trim().toString(), this.decPassword.trim()).toString(CryptoJS.enc.Utf8);
      return this.decryptedUserType;
    } else {
      return "type not fount please login again"
    }

  }

  /**************************************************************
   * Set Encrypted permissions ... 
   */
  setEncriptedPermissions(permissions) {
    this.userTypeEncryptionOutput = CryptoJS.AES.encrypt(JSON.stringify(permissions).trim().toString(), this.encPassword.trim()).toString();
    localStorage.setItem('permissions', this.userTypeEncryptionOutput)
  }

  /****************************************************************
   * GET decrypted permissions.......
   */
  decryptedPermissions;
  getDecryptedPermissions() {
    var permissions = localStorage.getItem('permissions');
    if (permissions != undefined) {
      this.decryptedPermissions = CryptoJS.AES.decrypt(permissions.trim().toString(), this.decPassword.trim()).toString(CryptoJS.enc.Utf8);
      return this.decryptedPermissions;
    } else {
      return "type not fount please login again"
    }
  }

  /**********************************************************
   * Encrypt user_id
   */
  encryptedUserId;
  encryptUserId(id) {
    this.encryptedUserId = CryptoJS.AES.encrypt(JSON.stringify(id).trim().toString(), this.encPassword.trim()).toString();
    return this.encryptedUserId
  }

  /**********************************************************
* Decrypt user_id
*/
  decryptedUserId;
  decryptUserId(id) {
    this.decryptedUserId = CryptoJS.AES.decrypt(id.trim().toString(), this.decPassword.trim()).toString(CryptoJS.enc.Utf8);
    return this.decryptedUserId
  }


}
