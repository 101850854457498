import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard, AuthGuardModule } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { TicketsModule } from './views/inrPay/tickets/tickets.module';
import { CommissionModule } from './views/inrPay/commission/commission.module';
import { InternationalMoneyTransferModule } from './views/inrPay/international-money-transfer/international-money-transfer.module';
import { MoneyTransferModule } from './views/inrPay/money-transfer/money-transfer.module';
import { QuickLinksModule } from './views/inrPay/quick-links/quick-links.module';
import { NewtabErrorComponent } from './views/pages/newtab-error/newtab-error.component';


const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/inrPay/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'user-type',
        loadChildren: () => import('./views/inrPay/usertype/usertype.module').then(m => m.UsertypeModule),
        data: { 'page_name': 'USER_TYPE' },
        canActivate: [AuthGuardModule]
      },
      {
        path: 'manage-user',
        loadChildren: () => import('./views/inrPay/manage-user/manage-user.module').then(m => m.ManageUserModule),
        data: { 'page_name': 'USER' },
        canActivate: [AuthGuardModule]
      },
      {
        path: 'manage-stock',
        loadChildren: () => import('./views/inrPay/manage-stock/manage-stock.module').then(m => m.ManageStockModule),
        data: { 'page_name': '' }
      },
      {
        path: 'recharge',
        loadChildren: () => import('./views/inrPay/recharges/recharges.module').then(m => m.RechargesModule),
        data: { 'page_name': 'RECHARGE' },
        canActivate: [AuthGuardModule]
      },
      {
        path: 'bill-payments',
        loadChildren: () => import('./views/inrPay/bill-payments/bill-payments.module').then(m => m.BillPaymentsModule),
        data: { 'page_name': 'BILLS' }
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/inrPay/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuardModule],
        data: {
          "page_name": 'DASHBOARD'
        },
      },
      {
        path: 'vendor',
        loadChildren: () => import('./views/inrPay/vendor/vendor.module').then(m => m.VendorModule),
        canActivate: [AuthGuardModule],
        data: {
          "page_name": 'VENDORS'
        }
      },
      {
        path: 'money-transfer',
        loadChildren: () => import('./views/inrPay/money-transfer/money-transfer.module').then(m => m.MoneyTransferModule),
        data: { 'page_name': 'MONEY_TRANSFER' },
        canActivate: [AuthGuardModule]
      },
      {
        path: 'payouts',
        loadChildren: () => import('./views/inrPay/payouts/payouts.module').then(m => m.PayoutsModule),
        data: { 'page_name': 'PAYOUTS' },
        canActivate: [AuthGuardModule]
      },
      {
        path: 'home',
        loadChildren: () => import('./views/inrPay/quick-links/quick-links.module').then(m => m.QuickLinksModule),
        // canActivate: [AuthGuardModule],
        // data: {
        //   "page_name": 'HOME'
        // },
      },
      {
        path: 'international-money-transfer',
        loadChildren: () => import('./views/inrPay/international-money-transfer/international-money-transfer.module').then(m => m.InternationalMoneyTransferModule)
      },
      {
        path: 'tickets',
        loadChildren: () => import('./views/inrPay/tickets/tickets.module').then(m => m.TicketsModule)
      },
      {
        path: 'commission',
        loadChildren: () => import('./views/inrPay/commission/commission.module').then(m => m.CommissionModule)
      },
      {
        path: 'wallet',
        loadChildren: () => import('./views/inrPay/wallet/wallet.module').then(m => m.WalletModule)
      },
      {
        path: 'travel-and-booking',
        loadChildren: () => import('./views/inrPay/travel-and-booking/travel-and-booking.module').then(m => m.TravelAndBookingModule)
      },
      {
        path: 'travel-admin',
        loadChildren: () => import('./views/inrPay/travel-and-booking-admin/travel-and-booking-admin.module').then(m => m.TravelAndBookingAdminModule)
      },
      {
        path: 'accounts',
        loadChildren: () => import('./views/inrPay/accounts/accounts.module').then(m => m.AccountsModule),
        canActivate: [AuthGuardModule],
        data: {
          "page_name": 'ACCOUNTS'
        }
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/inrPay/Reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuardModule],
        data: {
          "page_name": 'REPORTS'
        }
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/inrPay/settings/settings.module').then(m => m.SettingsModule),
        data: {
          "page_name": 'SETTINGS'
        },
        canActivate: [AuthGuardModule]
      },
      {
        path: 'cash-deposit',
        loadChildren: () => import('./views/inrPay/cash-deposit/cash-deposit.module').then(m => m.CashDepositModule),
        data: {
          "page_name": 'CASH_DEPOSIT'
        },
        canActivate: [AuthGuardModule]
      },

      { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      'type': 404,
      'title': 'Page Not Found',
      'desc': 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'err',
    component: NewtabErrorComponent,
    data: {
      type: 404
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
