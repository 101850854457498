import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ResetPasswordService } from 'src/app/services/resetPassword/reset-password.service';
import swal from 'sweetalert2'

@Component({
  selector: 'app-change-password-user',
  templateUrl: './change-password-user.component.html',
  styleUrls: ['./change-password-user.component.scss']
})
export class ChangePasswordUserComponent implements OnInit {

  @ViewChild('resetPasswordModal') resetOTPModal: ElementRef

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private authService: ResetPasswordService,
    private router: Router
  ) { }

  resetPasswordMobileForm: FormGroup;
  passwordForm: FormGroup

  currentPage = 1

  ngOnInit(): void {
    this.resetPasswordMobileForm = this.formBuilder.group({
      mobile: ['', Validators.required]
    })

    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.required],
      con_Password: ['', Validators.required]
    })
  }

  /******************************************
   * ResetPasswod OTP Enter Form.....
   */
  openOTPModal() {
    this.modalService.open(this.resetOTPModal, {
      centered: true,
      size: 'md',
      backdrop: 'static'
    })
  }


  /*******************************************
   * Reset Password phone submit..
   */
  settings = {
    length: 6,
    numbersOnly: true,
    timer: 0
  }

  resetPasswordPhoneResponse;
  responseOTPId;
  responseNumber;
  otpSent = false;
  otpLoading = false;
  resetPasswordPhoneSubmit() {
    this.otpLoading = true;
    this.settings = {
      length: 6,
      numbersOnly: true,
      timer: 100,
    }
    const value = {
      mobile: this.resetPasswordMobileForm.value.mobile
    }
    this.authService.resetPasswordPhoneSubmit(value).subscribe(
      data => {
        this.otpLoading = false;
        this.resetPasswordPhoneResponse = data;
        if (this.resetPasswordPhoneResponse.message == 'Invalid phone number') {
          swal.fire(
            { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: this.resetPasswordPhoneResponse.message, icon: 'error' }
          )
        } else {
          swal.fire(
            { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: 'OTP Sent Successfully', icon: 'success' }
          )
          this.otpSent = true
          this.responseNumber = this.resetPasswordPhoneResponse.data.phone;
          this.responseOTPId = this.resetPasswordPhoneResponse.data.otp_id;
        }
      }, err => {
        this.otpLoading = false;
        swal.fire(
          { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: 'Something went wrong ... ', icon: 'error' }
        )
      }
    )

  }


  otpSubmitResponse;
  OTP_from_User;

  onInputChange(e) {
    if (e.length == this.settings.length) {
      this.OTP_from_User = e;
      const value = {
        otpId: this.responseOTPId,
        otp: this.OTP_from_User,
      }
      this.authService.submitResetPasswordOtp(value).subscribe(
        data => {
          this.otpSubmitResponse = data;
          if (this.otpSubmitResponse.message == 'Invalid otp') {
            swal.fire(
              { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: this.otpSubmitResponse.message, icon: 'error' }
            )
          } else {
            swal.fire(
              { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: 'Mobile Number Verified', icon: 'success' }
            )
            this.currentPage = 2
          }
        }, err => {
          this.otpSubmitResponse = err;
          swal.fire(
            { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: 'Something went wrong ....', icon: 'error' }
          )
        }
      )
    } else if (e == -1) {
    } else if (e == -2) {
      alert("Resend otp?")
    }
  }


  /***************************************************************
   * Update Password ... 
   */
  updateBtnText = "UPDATE";
  resetPasswordResponse
  confirmPassword() {
    if (this.passwordForm.value.password == this.passwordForm.value.con_Password) {
      if (this.passwordForm.value.password.length >= 8) {
        this.updateBtnText = "PROCESSING ...";
        const value = {
          mobile: this.responseNumber,
          password: this.passwordForm.value.password
        }
        this.authService.resetPasswordSubmit(value).subscribe(
          data => {
            this.updateBtnText = "UPDATE";
            this.resetPasswordResponse = data;
            swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 2130,
              title: "Successfully Updated",
              icon: "success"
            })
            this.currentPage = 3
          }, err => {
            this.updateBtnText = "UPDATE";
            swal.fire(
              { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: 'Something went wrong please try again ...', icon: 'error' }
            )
          }
        )
      } else {
        swal.fire(
          { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: 'The password must be at least 8 characters ...', icon: 'error' }
        )
      }
    } else {
      swal.fire(
        { toast: true, position: 'top-end', showConfirmButton: false, timer: 2130, title: 'Password and confirm password are not equal ...', icon: 'error' }
      )
    }
  }


  laterToLogin() {
    this.modalService.dismissAll();
    this.currentPage = 1;
    this.passwordForm.reset();
    this.otpSent = false
    this.resetPasswordMobileForm.reset();
  }

  nowToLogin() {
    this.modalService.dismissAll();
    this.currentPage = 1;
    this.passwordForm.reset();
    this.resetPasswordMobileForm.reset();
    this.router.navigate(['/auth/login']);
    setTimeout(() => {
      location.reload();
    }, 500);
  }
}
