import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bbps-image',
  templateUrl: './bbps-image.component.html',
  styleUrls: ['./bbps-image.component.scss']
})
export class BbpsImageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
