import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class BBPSService {

  constructor(
    private http: HttpClient,
    private CommonService: CommonService
  ) { }

  service_ip_server = this.CommonService.service_ip_server;
  requestHeader = this.CommonService.httpOptions;

  handleErrorData = this.CommonService.handleError


  /********************************************
   * GET biller categories ... .. .. . 
   */
  getBillerCategories() {
    return this.http.get(this.service_ip_server + "biller-category", this.requestHeader);
  }


  /***********************************************
   * GET billers .....
   */
  getBillers(value) {
    return this.http.post(this.service_ip_server + "billers", {
      "category_id": value.id
    }, this.requestHeader)
  }

  /***********************************************
   * GET biller fields ...........
   */
  getBillerFields(value) {
    return this.http.post(this.service_ip_server + "biller-fields", {
      "category_id": value.categoryId,
      "biller_id": value.biller_id
    }, this.requestHeader)
  }

  /*********************************************
   * Bill Fetch .......................... 
   */
  billFetch(value) {
    return this.http.post(this.service_ip_server + "bill-fetch", {
      "biller_id": value.biller_id,
      "mobileNumber": value.mobileNumber,
      "categoryCode": value.categoryCode,
      "params": value.params
      // "name": value.name,
      // "value": value.value
    }, this.requestHeader).pipe(
      retry(3),
      catchError(this.handleErrorData)
    )
  }

  /***********************************************
   * GET fetched bill ... ... ..
   */
  getFetchedBill(value) {
    return this.http.post(this.service_ip_server + "getfetchbill", {
      "context": value.context
    }, this.requestHeader).pipe(
      retry(3),
      catchError(this.handleErrorData)
    )
  }


  payBill(value) {
    return this.http.post(this.service_ip_server + "paybill", {
      "amount": value.amount,
      "context": value.context,
      "category_code": value.category_code,
      "category_name": value.category_name,
      "cpd_type": "UTILITY",
      "params":value.params,
      "service":value.paymentDetails
    }, this.requestHeader)
  }

  // billSubmit(data) {
  //   return this.http.post(this.service_ip + "bbps", { data });
  // }

  /*************************************************
   * GET all complaints ... 
   */
  getAllComplaints(){
    return this.http.get(this.service_ip_server+"listcomplaints",this.requestHeader)
  }

  /*******************************************************
   * Register bbps complaint .. 
   */
  registerBBPSComplaint(value) {
    return this.http.post(this.service_ip_server + "raisecomplaint", {
      txnId: value.transactionId,
      txnDate: value.transactionDate,
      description: value.description
    },this.requestHeader)
  }

  /****************************************************************
   * Check complaint status ... .. .. .
   */
  checkComplaintStatus(value){
    return this.http.post(this.service_ip_server+"complaintstatus",{
      "referenceId":value.referenceId
    },this.requestHeader)
  }

}
