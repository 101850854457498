import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {

  constructor() { }


  /*************************************************************************
   * GET From Date to date format ... .
   */
  formatFromToDate(fromDate, toDate) {
    var selectedDay;
    var selectedMonth;
    var selectedToDay;
    var selectedToMonth
    if (fromDate.day.toString().length == 1) {
      selectedDay = "0" + fromDate.day
    } else {
      selectedDay = fromDate.day
    }
    if (fromDate.month.toString().length == 1) {
      selectedMonth = "0" + fromDate.month
    } else {
      selectedMonth = fromDate.month
    }

    if (toDate.day.toString().length == 1) {
      selectedToDay = "0" + toDate.day
    } else {
      selectedToDay = toDate.day
    }
    if (toDate.month.toString().length == 1) {
      selectedToMonth = "0" + toDate.month
    } else {
      selectedToMonth = toDate.month
    }

    var fromDateSelected = fromDate.year + "-" + selectedMonth + "-" + selectedDay
    var todateSelected = toDate.year + "-" + selectedToMonth + "-" + selectedToDay

    var value = {
      fromDate: fromDateSelected,
      toDate: todateSelected
      // fromMonth:selectedMonth,
      // fromDay:selectedDay,
      // toMonth:selectedToMonth,
      // toDay:selectedToDay
    }

    return value
  }



  selectedDate;
  selectedMonth;
  selectedDay;
  getFormattedSingleDate(value){
    this.selectedDate = value;
    if (this.selectedDate.month.toString().length == 1) {
      this.selectedMonth = "0" + this.selectedDate.month.toString()
    } else {
      this.selectedMonth = this.selectedDate.month.toString()
    }
    if (this.selectedDate.day.toString().length == 1) {
      this.selectedDay = "0" + this.selectedDate.day.toString()
    } else {
      this.selectedDay = this.selectedDate.day.toString()
    }

    var date = this.selectedDate.year + "-" + this.selectedMonth + "-"+this.selectedDay;

    return date
  }

}
